.ant-alert {
  @extend .text-body;
  align-items: flex-start;
  padding: 14px 12px;
  border-radius: 6px;
  .ant-alert-icon {
    margin-right: 12px;
    transform: translateY(1px);
  }
}
.ant-alert-content {
  transform: translateY(2px);
}
.ant-alert-close-icon {
  @include flex-center;
  width: 24px;
  height: 24px;
  .icon {
    font-size: 12px;
  }
}
.ant-alert-success {
  background-color: color(green-200);
  border: none;
  .ant-alert-icon,
  .ant-alert-close-icon {
    color: color(green-accent);
  }
  .ant-alert-message {
    color: color(green-accent);
    font-weight: 600;
  }
}
.ant-alert-info {
  background-color: color(primary-100);
  border: none;
  .ant-alert-icon,
  .ant-alert-close-icon {
    color: color(primary-1000);
  }
  .ant-alert-message {
    color: color(primary-1000);
    font-weight: 600;
  }
}
.ant-alert-warning {
  background-color: color(yellow-200);
  border: none;
  .ant-alert-icon,
  .ant-alert-close-icon {
    color: color(yellow-accent);
  }
  .ant-alert-message {
    color: color(yellow-accent);
    font-weight: 600;
  }
}
.ant-alert-error {
  background-color: color(red-200);
  border: none;
  .ant-alert-icon,
  .ant-alert-close-icon {
    color: color(red-1000);
  }
  .ant-alert-message {
    color: color(red-1000);
    font-weight: 600;
  }
}
