.ant-pagination {
  @extend .text-caption;
  color: color(primary-1000);
}
.ant-pagination-item {
  background-color: transparent;
  border: none;
  border-radius: $border-radius-lg;
  font-weight: 600;
  font-family: inherit;
  line-height: 32px;
  transition: background-color 0.1s ease;
  a {
    color: color(primary-1000);
    transition: color 0.1s ease;
  }
  &:hover {
    background-color: color(primary-1000);
    a {
      color: white;
    }
  }
}
.ant-pagination-item-active {
  background-color: color(primary-1000);
  border: none;
  &:hover a,
  a {
    color: white;
  }
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  border-radius: $border-radius-lg;
  .ant-pagination-item-link {
    @extend .ant-btn;
    @extend .ant-btn-icon-only;
    @extend .ant-btn-tertiary;
    width: 32px;
    height: 32px;
    .anticon,
    .ant-pagination-item-container {
      display: none;
    }
    &::before {
      font-family: 'icomoon';
      font-size: 10px;
      line-height: 1;
    }
  }
}
.ant-pagination-prev .ant-pagination-item-link::before {
  content: '\e904';
}
.ant-pagination-next .ant-pagination-item-link::before {
  content: '\e905';
}
.ant-pagination-jump-prev .ant-pagination-item-link::before {
  content: '\e902';
}
.ant-pagination-jump-next .ant-pagination-item-link::before {
  content: '\e903';
}
