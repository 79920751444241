$card-bg: #f8f9fc;
$card-border: #5396ff;
.consultation__wrap {
  display: flex;
  width: 100%;
  height: calc(100vh - $main-header-height);
  overflow: hidden;
}
.consultation__chat {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 $gap-xs;
  background-color: white;
  @include media-breakpoint-up(md) {
    margin-left: $gap-md;
    margin-right: $gap-md;
  }
}
.consultation__chat-history {
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
  flex-grow: 1;
  min-height: 0;
  .infinite-scroll-component {
    display: flex;
    flex-direction: column-reverse;
  }
}

.consultation__chat-section {
  margin-top: 16px;
  &:first-child {
    margin-top: 64px;
  }
}
.consultation__chat-back-to-top {
  right: 345px;
  bottom: 90px;
}
.consultation__chat-divider {
  display: flex;
  margin-bottom: 16px;
  &::before,
  &::after {
    content: '';
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid color(secondary-100);
    transform: translateY(50%);
  }
}
.consultation__chat-divider-txt {
  @extend .text-caption;
  padding: 0 16px;
  color: color(secondary-900);
  white-space: nowrap;
}
.consultation__chat-msg {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0 12px;
  padding: 16px 8px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: color(secondary-50);
    .consultation__chat-msg__dropdown {
      opacity: 1;
    }
  }
  @include media-breakpoint-up(md) {
    margin: 0 24px;
  }
}
.consultation__chat-msg--edit {
  background-color: color(secondary-50);
  .consultation__chat-msg__dropdown {
    opacity: 1;
  }
}
.consultation__chat-msg--sidebar {
  margin: 0;
}

.consultation__chat-msg__dropdown {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.consultation__chat-end-msg {
  @include flex-center-y;
  width: 100%;
  max-width: 458px;
  padding: 16px;
  background-color: color(secondary-50);
  border-radius: $border-radius-sm;
  box-shadow: inset 0 0 0 1px color(secondary-100);
}
.consultation__chat-card__wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 0 12px;
  padding: 16px 8px;
  @include media-breakpoint-up(md) {
    margin: 0 24px;
  }
}
.consultation__chat-sidebar-email {
  word-wrap: break-word;
}
.consultation__chat-card {
  position: relative;
  max-width: 736px;
  padding: 16px 12px;
  background-color: $card-bg;
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px color(secondary-100);
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2px;
    background-color: $card-border;
    border-radius: 2px;
  }
  @include media-breakpoint-up(md) {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.consultation__chat-card--cancelled {
  max-width: 488px;
  &::before {
    display: none;
  }
}
.consultation__chat-card__icon {
  @include flex-center;
  flex: none;
  width: 24px;
  height: 24px;
  background-color: color(red-1000);
  border-radius: 50%;
  .icon {
    color: white;
    font-size: 12px;
  }
}
.consultation__chat-card__time {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include media-breakpoint-up(md) {
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 24px;
  }
}
.consultation__chat-text {
  white-space: pre-wrap;
}
.consultation__chat-input {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 12px 72px 12px 12px;
  @include media-breakpoint-up(md) {
    padding-left: 24px;
  }
}
.consultation__chat-input__attachment {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 12px 72px 12px 12px;
  @include media-breakpoint-up(md) {
    padding-left: 24px;
  }
}
.consultation__chat-input-uploader {
  border: none !important;
  background: white !important;

  .ant-upload {
    padding: 0 !important;
  }
}

.consultation__chat-input-wrap {
  padding: 32px 12px 16px;
  @include media-breakpoint-up(md) {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.consultation__chat-input-wrap-sidebar {
  padding: 0;
}

.consultation__chat-input-border {
  border-radius: 2px;
  box-shadow: inset 0 0 0 1px color(secondary-100);
  overflow: hidden;
}
.consultation__chat-input-edit {
  padding: 12px 52px 10px;
  background-color: color(secondary-50);
  color: color(secondary-900);
  font-weight: 600;
  @include media-breakpoint-up(md) {
    padding-right: 64px;
    padding-left: 64px;
  }
}
.consultation__chat-edit-wrap {
  position: relative;
}
.consultation__chat-edit-close-btn {
  position: absolute;
  right: 24px;
  bottom: 8px;
}
.consultation__chat-btn {
  position: absolute;
  right: 24px;
  bottom: 8px;
}
.consultation__chat-attach {
  flex-shrink: 0;
  margin-right: 20px;
  cursor: pointer;
}
textarea.consultation__chat-input-textarea {
  padding: 0;
  &::placeholder {
    color: color(secondary-200);
  }
}
.consultation__chat-new-msgs {
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  background-color: color(primary-100);
  border-radius: 2px;
  color: color(primary-1000);
  overflow: hidden;
  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
.consultation__chat-new-msgs__btn {
  flex: none;
  margin-left: auto;
  padding: 0 16px;
  background-color: color(primary-100);
  border-left: 1px solid white;
  border-radius: 0;
  color: color(primary-1000);
  font-weight: 400;
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(primary-200);
    border-left: 1px solid white;
    color: color(primary-1000);
  }
  &:not([disabled]):active {
    background-color: color(primary-300);
    border-left: 1px solid white;
    color: color(primary-1000);
  }
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin-left: 0;
  }
}
.consultation__chat-offline {
  position: absolute;
  top: 64px;
  right: 16px;
  left: 16px;
  z-index: 10;
}
.consultation__chat-uploading {
  position: absolute;
  top: 8px;
  right: 16px;
  left: 16px;
  z-index: 20;
  background-color: color(secondary-50);
  border: 1px solid color(secondary-100);
  border-radius: $border-radius-sm;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    width: 420px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
}
.consultation__chat-uploading__progress {
  @extend .text-caption;
  display: flex;
  padding: 3px 0px 3px 0px;

  background-color: color(primary-100);
  border-radius: $border-radius-sm;
  color: color(primary-1000);
  font-weight: 600;
}
.consultation__chat-uploading__cancel-btn {
  @extend .text-caption;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
.consultation__chat-attachment {
  @include flex-center-y;
  height: 40px;
  padding: 0;
  background: none !important;
  border: none !important;
  border-radius: 0;
  color: color(secondary-1000);
  font-weight: 400;
  text-align: left;
  &:hover,
  &:focus,
  &:active {
    color: color(secondary-1000);
  }
}
.consultation__back-btn,
.consultation__sidebar-btn {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 10;
  background-color: white;
  border-color: color(secondary-100);
  color: color(secondary-800);
  box-shadow: $shadow2;
  &:hover,
  &:focus {
    background-color: color(secondary-50);
    border-color: color(secondary-100);
    color: color(secondary-800);
  }
}
.consultation__sidebar-btn {
  left: auto;
  right: 16px;
  @include media-breakpoint-up(xl) {
    display: none;
  }
}
.consultation__sidebar-wrap {
  display: none;
  width: $consultation-sidebar-width;
  background-color: white;
  @include media-breakpoint-up(xl) {
    display: block;
  }
  * {
    white-space: break-spaces;
    word-break: break-word;
  }
}

.consultation__drawer {
  .ant-drawer-content-wrapper {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
  .ant-drawer-header {
    padding: 16px 24px;
  }
  .ant-drawer-header-title {
    justify-content: flex-end;
  }
  .ant-drawer-body {
    padding: 0;
  }
}
.consultation__assessment-alert-draft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ant-alert-message {
    font-size: 12px;
  }
}
.consultation__questionnaire-drawer,
.consultation__assessment-drawer {
  .ant-drawer-content-wrapper {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
  .ant-drawer-header {
    padding: 16px 24px;
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    [data-simplebar] {
      max-height: calc(100% - 96px);
    }
  }
  .consultation__chat {
    margin: 0;
  }
}
.consultation__questionnaire-drawer__footer,
.consultation__assessment-drawer__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  padding: 24px;
  background-color: white;
  box-shadow: $shadow5;
}
.consultation__assessment-drawer__footer {
  box-shadow: inset 0 1px 0 color(secondary-100);
}
.consultation__assessment-grid {
  display: grid;
  grid-template-columns: minmax(30%, min-content) 1fr;
  grid-gap: 16px;
  @include media-breakpoint-up(md) {
    grid-template-columns: auto 1fr;
  }
  .national-provider-identifier {
    overflow-wrap: anywhere;
  }
}
.consultation__assessment-grid--signature {
  grid-template-columns: 1fr 184px;
  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr auto;
  }
}
.consultation__assessment-signature {
  @include flex-center-x;
  width: 184px;
  border: 1px solid color(secondary-100);
  border-radius: $border-radius-sm;
  overflow: hidden;
  img {
    max-width: 100%;
  }
}
.consultation__assessment-item {
  margin-right: -16px;
  margin-left: -16px;
  padding: 8px 16px;
  background-color: color(secondary-50);
  border-radius: $border-radius-sm;
  & + & {
    margin-top: 8px;
  }
}
.consultation__assessment-radio {
  @include flex-center-y;
  width: 100%;
  height: 40px;
  padding: 0 8px;
  background-color: white;
  box-shadow: inset 0 0 0 1px color(secondary-300);
  .ant-radio {
    top: 0;
  }
}
.consultation__add-assessment-modal {
  .ant-modal-body {
    padding: 0 0 24px;
  }
  .ant-modal-footer {
    padding-top: 24px;
    border-top: 1px solid color(secondary-100);
  }
}
