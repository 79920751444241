.patients__tabs {
  .ant-tabs-nav::before {
    display: block;
    border-bottom: 2px solid color(secondary-100);
  }
}
.patients__table-loader {
  min-height: 300px;
}
.patients__table--appointments {
  .ant-table-container table > thead > tr:first-child th:last-child {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }
}
.patients__table--assessments {
  .ant-table-tbody > tr > td {
    cursor: pointer;
  }
}
.patients__table--list {
  .ant-table-thead > tr > th {
    padding: 17px 12px;
    background-color: color(secondary-50);
  }
  .ant-table-tbody > tr > td {
    padding: 11px 12px;
  }
}
