$main-font-family: 'Inter', sans-serif;
$gap-xs: 12px;
$gap-md: 24px;
$shadow1: 0 2px 6px rgb(13 15 51 / 0.12);
$shadow2: 0 4px 6px rgb(61 65 133 / 0.08);
$shadow3: 0 8px 14px rgb(61 65 133 / 0.08);
$shadow4: 0 12px 36px rgb(16 19 82 / 0.12);
$shadow5: 0 -2px 8px rgb(16 19 82 / 0.1);
$shadow6: 0 5px 8px rgb(29 75 255 / 0.22);
$overlay-color: rgb(0 1 40 / 0.6);
$border-radius-sm: 2px;
$border-radius-md: 6px;
$border-radius-lg: 8px;
$main-header-height: 64px;
$main-sidebar-width: 236px;
$closed-sidebar-width: 80px;
$consultation-sidebar-width: 288px;
