html {
  font-family: $main-font-family;
  font-size: 62.5%;
  line-height: (24 / 14);
}
body {
  @extend .text-body;
  overflow-x: hidden;
  background-color: color(bg-color);
  color: color(secondary-1000);
  font-family: $main-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  > #__next {
    min-height: 100%;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: color(secondary-1000);
  font-weight: 600;
}
ul {
  list-style: none;
}
ul,
ol {
  margin: 0;
  padding: 0;
}
article,
aside,
footer,
header,
main,
nav,
section {
  display: block;
}
button {
  height: auto;
  margin: 0;
  padding: 0;
  border: none;
  background: 0 0;
  color: inherit;
  font-family: $main-font-family;
  font-size: inherit;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    padding: 0;
    border: none;
    outline: 0;
  }
}
input {
  @extend .text-body;
  color: color(secondary-1000);
  &::placeholder {
    color: color(secondary-800);
  }
}
input:focus,
textarea:focus {
  outline: 0;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
}
p {
  margin: 0;
}
a {
  color: color(primary-1000);
  text-decoration: none;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
strong {
  font-weight: 600;
}
