.ant-drawer-mask {
  background-color: $overlay-color;
}
.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none !important;
}
.ant-drawer-body {
  @extend .text-body;
  padding: 0 4px;
}
.ant-drawer-header {
  flex: none;
  padding: 20px 12px 24px;
  border-radius: 0;
}
.ant-drawer-close {
  @extend .ant-btn;
  @extend .ant-btn-control;
  @extend .ant-btn-icon-only;
  order: 2;
  margin-right: 0;
  .icon {
    font-size: 18px;
  }
}
.ant-drawer-title {
  @extend .text-subheader;
  color: color(black-text);
  font-weight: 600;
}
.ant-drawer-static .ant-drawer-body {
  padding: 0;
}
