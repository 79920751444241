.ant-form {
  @extend .text-body;
  color: color(secondary-1000);
}
.ant-form-item {
  @extend .text-body;
  color: color(secondary-1000);
  margin-bottom: 16px;
}
.ant-form-vertical .ant-form-item-label {
  @extend .text-caption;
}
.ant-form-item-label > label {
  @extend .text-caption;
  color: color(secondary-800);
}
.ant-form-item-control > label {
  padding-bottom: 8px;
  @extend .text-caption;
  color: color(secondary-800);
}
