@mixin flex-start {
  display: flex;
  align-items: flex-start;
}
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin flex-center-x {
  display: flex;
  justify-content: center;
}
@mixin flex-center-y {
  display: flex;
  align-items: center;
}
@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
