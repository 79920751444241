$vc-index: 15;
$vc-video-gradient: linear-gradient(129.58deg, #358ca8 40.64%, rgba(24, 44, 79, 0.89) 128.31%);

.vc-main {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.vc-main--split {
  .vc-video__wrap {
    width: 50%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .vc-video {
    width: auto;
  }
  .vc-video__preview {
    width: 50%;
    position: relative;
    top: auto;
    right: auto;
    height: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .vc-video__preview-video {
    width: auto;
    height: 100%;
    min-width: 0;
    min-height: 0;
    vertical-align: middle;
  }
  .vc-controls--preview {
    display: none;
  }
  .vc-normal-screen-btn {
    display: block;
  }
}
.vc-controls {
  position: absolute;
  z-index: $vc-index;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 80px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(0, 1, 40, 0.6);
    .vc-time {
      opacity: 1;
    }
  }
}
.vc-controls--preview {
  gap: 16px;
  justify-content: flex-end;
  height: 40px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: rgba(0, 1, 40, 0.6);
}
.vc-controls__buttons {
  @include flex-center-y;
  gap: 16px;
}
.vc-adds {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  display: flex;
  gap: 16px;
}
.vc-skeleton__loader {
  @include flex-center;
  .icon-loader {
    color: color(white-100);
    font-size: 42px;
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
.vc-lost-connection {
  color: white;
  text-align: center;
}
.vc-time {
  @include flex-center;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.vc-video__wrap {
  @include flex-center;
  width: 100%;
  height: 100%;
  background: $vc-video-gradient;
}
.vc-video {
  width: 1280px;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.vc-video__preview {
  @include flex-center;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 176px;
  height: 320px;
  box-shadow: $shadow1;
  z-index: $vc-index - 1;
  background: $vc-video-gradient;
}
.vc-video__preview-video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
.vc-normal-screen-btn {
  position: absolute;
  z-index: $vc-index + 1;
  display: none;
  top: 16px;
  right: 16px;
}
.vc-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $vc-index + 2;
  display: flex;
  flex-direction: column;
  width: 376px;
  height: 100%;
  background-color: white;
  box-shadow: $shadow4;
}
.vc-sidebar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid color(secondary-100);
}
.vc-sidebar__header-close {
  padding-right: 4px;
  padding-left: 4px;
  color: color(secondary-800);
  .icon {
    font-size: 12px;
  }
}
.vc-sidebar__body {
  flex-grow: 1;
  padding: 16px 24px;
  overflow-y: auto;
}
.vc-sidebar__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  padding: 24px;
  box-shadow: $shadow5;
}
.vc-welcome {
  @include flex-center;
  width: 176px;
  height: 320px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  background: linear-gradient(180deg, #dbe6ff 15.85%, #fff 100%);
  .ant-avatar-string {
    font-size: 24px;
    font-weight: 400;
  }
}

.vc-welcome-page {
  @include flex-center;
  width: 640px;
  height: 348px;
  margin-right: auto;
  margin-bottom: 16px;
  margin-left: auto;
  background: linear-gradient(180deg, #dbe6ff 15.85%, #fff 100%);
  .ant-avatar-string {
    font-size: 24px;
    font-weight: 400;
  }
}
.vc-welcome-page-wrap {
  position: relative;
  width: 100%;
  height: 348px;
  overflow: hidden;
}

.vc-welcome-page-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vc-welcome-config-buttons {
  width: 224px;
}

.vc-welcome-join-buttons {
  @include flex-center;
  height: 100%;
}

.vc-welcome__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
