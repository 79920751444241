.header-menu {
  display: flex;
  gap: 40px;
  margin-right: 52px;
}
.header-menu__item {
  font-weight: 600;
  color: color(secondary-900);
}
.landing-top-section {
  position: relative;
  height: 600px;
  overflow: hidden;
  background: linear-gradient(116.28deg, #fdfdfd 13.08%, #e5edff 98.96%);
  @include media-breakpoint-up(xl) {
    height: 700px;
  }
}
.landing-top-section__content {
  position: relative;
  max-width: 486px;
  margin-bottom: 100px;
  padding-top: 124px;
}
.landing-title {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: (64 / 48);
  color: color(secondary-1000);
}
.landing-title__deco-text {
  position: relative;
  z-index: 0;
  display: inline-block;
  color: color(primary-1000);
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 40px;
    width: 100%;
    height: 22px;
    background-color: color(primary-100);
  }
}
.landing-subtitle {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: (24 / 16);
  text-transform: uppercase;
  color: color(primary-1000);
}
.landing-simple-text {
  font-size: 16px;
  line-height: (24 / 16);
  color: color(secondary-900);
}
.landing-top-section__panel {
  position: relative;
  padding: 16px;
  border-radius: 10px;
  background-color: rgba(white, 0.8);
}
.landing-top-section__panel-content {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  background-color: white;
  border: 1px solid color(secondary-200);
  border-radius: 2px;
}
.landing-top-section__image {
  position: absolute;
  top: 5px;
  right: 15px;
  display: none;
  width: 502px;
  height: 502px;
  @include media-breakpoint-up(lg) {
    display: block;
  }
  @include media-breakpoint-up(xl) {
    top: -45px;
    width: 742px;
    height: 742px;
  }
  @include media-breakpoint-up(xxl) {
    width: 762px;
    height: 762px;
  }
}
.landing-top-section__image-inner {
  position: absolute;
  top: 30px;
  left: 35px;
  width: 432px;
  height: 432px;
  background-color: color(secondary-400);
  border-radius: 50%;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    top: 60px;
    left: 60px;
    width: 642px;
    height: 642px;
  }
}
.landing-top-section__image-inner-pic {
  position: absolute;
  top: -7px;
  left: 5px;
  @include media-breakpoint-up(xl) {
    left: 85px;
  }
}
.landing-verical-divider {
  width: 1px;
  height: 24px;
  background-color: color(secondary-200);
}
.landing-select {
  width: 40%;
  .ant-select-selector {
    padding-left: 28px !important;
    &:before {
      left: 0 !important;
    }
  }
}
.landing-datepicker {
  padding: 0;
  width: 20%;
  .ant-picker-input {
    flex-direction: row-reverse;
  }
  .ant-picker-suffix {
    margin-left: 0;
    margin-right: 8px;
  }
}
.landing-top-section__icon-doctor {
  position: absolute;
  top: 68px;
  left: 30px;
  @include media-breakpoint-up(xl) {
    top: 118px;
    left: 50px;
  }
}
.landing-top-section__icon-arrow {
  position: absolute;
  top: 110px;
  right: 0;
  @include media-breakpoint-up(xl) {
    top: 130px;
    right: 42px;
  }
}
.landing-top-section__icon-time {
  position: absolute;
  bottom: 30px;
  right: 52px;
  @include media-breakpoint-up(xl) {
    bottom: 20px;
    right: 150px;
  }
}
.landing-section__items {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 60px;
}
.landing-section__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  border-radius: 8px;
  padding: 32px;
  box-shadow: $shadow6;
}
.landing-section__items--nowrap {
  flex-wrap: nowrap;
  .landing-section__item {
    flex-basis: 100%;
  }
}
.landing-appoint-plan-list {
  display: flex;
  gap: 96px;
}
.landing-squad-icon {
  @include flex-center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: color(primary-800);
  box-shadow: $shadow6;
  color: white;
}
.landing-squad-icon--white {
  width: 32px;
  height: 32px;
  background-color: white;
  color: color(primary-800);
  box-shadow: none;
}
.landing-bottom {
  @include flex-center-y;
  justify-content: space-between;
  padding: 40px;
  border-radius: 8px;
  box-shadow: $shadow6;
}
.landing-bottom__title {
  font-size: 36px;
  line-height: (54 / 36);
  font-weight: 600;
}
.landing-footer {
  border-top: 1px solid color(secondary-100);
}
.landing-footer__content {
  display: flex;
  gap: 100px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid color(secondary-100);
}
.landing-footer__copyright {
  @include flex-center-y;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}
.landing-footer__copyright-icons {
  display: flex;
  gap: 8px;
}
.landing-footer__copyright-icon {
  @include flex-center;
  width: 36px;
  height: 36px;
  color: color(secondary-800);
  border: 1px solid color(secondary-300);
  border-radius: 50%;
}
.landing-footer__subheader {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: (20 / 16);
  text-transform: uppercase;
  color: color(secondary-900);
}
.landing-facility {
  display: flex;
  margin-bottom: 24px;
}
.landing-facility__icon {
  @include flex-center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  background-color: color(primary-800);
  border-radius: 50%;
  color: white;
}
.landing-second-section__image-wrap {
  position: absolute;
  top: 0;
  left: -24px;
  display: none;
  width: 463px;
  height: 463px;
  @include media-breakpoint-up(lg) {
    display: block;
  }
  @include media-breakpoint-up(xl) {
    width: 633px;
    height: 633px;
  }
}
.landing-second-section__image {
  border-radius: 50%;
  overflow: hidden;
}
.landing-second-section__image-top-icon {
  position: absolute;
  top: 24px;
  left: 47px;
}
.landing-call-center {
  position: absolute;
  bottom: 10px;
  right: 7px;
  display: inline-flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: color(primary-800);
  color: white;
  font-weight: 600;
}
.landing-analytics {
  position: absolute;
  top: 104px;
  right: -53px;
  width: 198px;
  padding: 12px;
  font-size: 36px;
  line-height: (54 / 36);
  color: color(primary-800);
  box-shadow: $shadow6;
  border-radius: 8px;
  background-color: white;
}
