.ant-input,
.ant-input-password,
.ant-input-affix-wrapper {
  @extend .text-body;
  padding: 9px 12px;
  border: 1px solid color(secondary-300);
  border-radius: $border-radius-sm;
  color: color(secondary-1000);
  &::placeholder {
    @extend .text-body;
    color: color(secondary-800);
  }
  &:hover,
  &:focus,
  &.ant-input-focused {
    border-color: color(primary-1000);
    box-shadow: none;
  }
  &[disabled] {
    background-color: color(secondary-50);
    border-color: color(secondary-300);
    color: color(secondary-1000);
  }
  &[readonly] {
    pointer-events: none;
  }
}
.ant-input-status-error:not(.ant-input-disabled, .ant-input-borderless).ant-input {
  border-color: color(red-1000);
  &:focus {
    border-color: color(red-1000);
    box-shadow: none;
  }
}
.ant-input-prefix {
  margin-right: 8px;
  color: color(secondary-800);
}
.ant-input-suffix {
  margin-left: 8px;
}
.ant-input-lg,
.ant-input-password-large,
.ant-input-affix-wrapper-lg {
  padding: 13px 12px;
}
.ant-input-sm {
  padding: 5px 12px;
}
textarea.ant-input {
  @extend .text-body;
  padding: 11px 12px;
  border-radius: $border-radius-sm;
  &::-webkit-resizer {
    display: none;
  }
}
textarea.ant-input--round {
  padding: 8px 12px;
  border-radius: $border-radius-lg;
}
.textarea__wrap {
  position: relative;
  &::after {
    content: '\e910';
    position: absolute;
    right: 8px;
    bottom: 8px;
    font-family: 'icomoon';
    font-size: 24px;
    line-height: 1;
    color: color(secondary-800);
    pointer-events: none;
  }
}
@-moz-document url-prefix('') {
  .textarea__wrap::after {
    display: none;
  }
}
.ant-input-password-icon {
  @include flex-center;
  color: color(secondary-800);
  cursor: pointer;
  transition: color 0.4s ease;
  &:hover {
    color: color(secondary-1000);
  }
}
.ant-input-affix-wrapper {
  &:not(.ant-input-affix-wrapper-disabled):hover,
  &:not(.ant-input-affix-wrapper-disabled):focus,
  &:not(.ant-input-affix-wrapper-disabled):focus-within,
  &:not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
    border-color: color(primary-1000);
    box-shadow: none;
  }
  .ant-input {
    border-radius: 2px;
  }
}
.main-input__message {
  @extend .text-caption;
  display: flex;
  margin-top: 8px;
  color: color(secondary-1000);
  .icon {
    margin-right: 4px;
    transform: translateY(-3px);
  }
}
.main-input__message--info {
  color: color(secondary-800);
}
.main-input__message--error {
  color: color(red-1000);
}
.main-input__password-progress {
  position: relative;
  width: 100%;
  height: 4px;
  margin-top: 4px;
  border-radius: 2px;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 2px;
  }
}
.main-input__password-progress--step-1 {
  background-color: color(secondary-200);
  &::after {
    width: 0;
  }
}
.main-input__password-progress--step-2 {
  background-color: color(red-200);
  &::after {
    width: 33.33%;
    background-color: color(red-1000);
  }
}
.main-input__password-progress--step-3 {
  background-color: color(yellow-200);
  &::after {
    width: 66.66%;
    background-color: color(yellow-accent);
  }
}
.main-input__password-progress--step-4 {
  background-color: color(green-accent);
  &::after {
    width: 100%;
    background-color: color(green-accent);
  }
}
.main-input__password-recovery {
  display: flex;
  position: absolute;
  right: 0;
  bottom: calc(100% + 8px);
}
.ant-input-group.ant-input-group-compact > *:first-child {
  border-top-left-radius: $border-radius-sm;
  border-bottom-left-radius: $border-radius-sm;
}
.ant-input-group.ant-input-group-compact > *:last-child {
  border-top-right-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
}
.ant-form-item-explain-error {
  color: color(red-1000);
}
.currency-input {
  @extend .text-body;
  width: 100%;
  padding: 13px 12px;
  border: 1px solid color(secondary-300);
  border-radius: $border-radius-sm;
  transition: border-color 0.3s ease;
  &:hover,
  &:focus {
    border-color: color(primary-1000);
  }
  &[disabled] {
    background-color: color(secondary-50);
    border-color: color(secondary-300);
    color: color(secondary-1000);
    cursor: not-allowed;
  }
  .ant-form-item-has-error & {
    border-color: color(red-1000);
  }
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled, .ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: color(red-1000);
  box-shadow: none;
}
.ant-input-borderless {
  border: none;
}
