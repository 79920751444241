.ant-avatar {
  background: color(primary-1000);
}
.ant-avatar-string {
  font-size: 14px;
  font-weight: 600;
}
.ant-avatar-image {
  border: 2px solid white;
  box-shadow: $shadow1;
}
.ant-avatar-icon {
  @include flex-center;
  background-color: color(secondary-50);
  color: color(secondary-300);
}
