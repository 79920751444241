.ant-checkbox-wrapper {
  @include flex-center-y;
  @extend .text-body;
  color: color(secondary-1000);
  &.text-truncate {
    .ant-checkbox + span {
      @extend .text-truncate;
    }
  }
  .checkbox-field-label & {
    margin-right: 10px;
  }
}
.ant-checkbox {
  top: auto;
  color: color(secondary-1000);
  &:hover {
    .ant-checkbox-inner {
      border-color: color(primary-1000);
    }
  }
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: color(primary-1000);
      border-color: color(primary-1000);
    }
    &::after {
      display: none;
    }
  }
}
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border: 2px solid color(primary-200);
  border-radius: 4px;
}
.ant-checkbox + span {
  padding-left: 12px;
  padding-right: 0;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
