.ant-switch {
  min-width: 40px;
  height: 24px;
  background: color(primary-200);
  &:focus {
    box-shadow: none;
  }
  &::after {
    display: none;
  }
  .ant-click-animating-node {
    display: none;
  }
}
.ant-switch-checked {
  background: color(green-1000);
}
.ant-switch-loading,
.ant-switch-disabled {
  opacity: 1;
  background: color(secondary-100);
}
.ant-switch-disabled + span {
  color: color(secondary-300);
}
.ant-switch-handle {
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
  &::before {
    border-radius: 8px;
    box-shadow: $shadow2;
  }
  .ant-switch-checked & {
    left: calc(100% - 16px - 4px);
    &::before {
      box-shadow: $shadow3;
    }
  }
}
