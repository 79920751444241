.ant-tag {
  @extend .text-caption;
  margin-right: 0;
  padding: 2px 8px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
}
.ant-tag-blue {
  background: color(primary-pressed);
}
.ant-tag-processing {
  background: color(primary-1000);
}
.ant-tag-success {
  background: color(green-1000);
}
.ant-tag-warning {
  background: color(yellow-1000);
}
.ant-tag-error {
  background: color(red-1000);
}
.ant-tag-default {
  background: color(secondary-800);
}
