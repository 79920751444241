.ant-divider {
  border-top: 1px solid color(secondary-100);
}
.ant-divider-vertical {
  top: 0;
  border-left: 1px solid color(secondary-100);
  &.in-secondary-300 {
    border-color: color(secondary-300);
  }
}
