.skeleton__avatar {
  width: 80px;
  height: 80px;
  background-color: color(secondary-100);
  border-radius: 50%;
}
.skeleton__paragraph {
  height: 24px;
  background-color: color(secondary-100);
  border-radius: $border-radius-sm;
}
.skeleton__button-small {
  background-color: color(secondary-100);
  border-radius: $border-radius-lg;
  height: 32px;
}
.skeleton__loader {
  @include flex-center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.skeleton__loader--relative {
    position: relative;
    top: inherit;
    left: inherit;
    transform: inherit;
  }
  .icon-loader {
    color: color(primary-1000);
    font-size: 42px;
    animation-name: spin;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
