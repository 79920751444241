.question-alert {
  @include flex-center-y;
  justify-content: space-between;
  padding: 20px 24px;
  background-color: color(primary-100);
  color: color(primary-1000);
  border-radius: 2px;
  box-shadow: $shadow2;
  font-weight: 700;
}
