.multi-date-calendar {
  &.rmdp-wrapper {
    width: 100%;
    border-radius: 0;
  }
  &.rmdp-border {
    border: none;
  }
  .rmdp-calendar {
    width: 100%;
    padding: 0;
  }
  .rmdp-header {
    height: auto;
    margin-top: 0;
    padding: 16px $gap-xs;
    border-bottom: 1px solid color(secondary-100);
    line-height: inherit;
    @include media-breakpoint-up(md) {
      padding-left: $gap-md;
      padding-right: $gap-md;
    }
  }
  .rmdp-arrow-container {
    @include flex-center;
    margin: 0;
    border-radius: 0;
    color: color(secondary-800);
    font-size: 14px;
    line-height: 1;
    transition: color 0.3s ease;
    &:hover {
      background-color: transparent;
      color: color(secondary-1000);
      box-shadow: none;
    }
    .rmdp-arrow {
      display: none;
    }
    &.rmdp-left::after {
      content: '\e904';
      font-family: 'icomoon';
    }
    &.rmdp-right::after {
      content: '\e905';
      font-family: 'icomoon';
    }
  }
  .rmdp-header-values {
    color: color(secondary-1000);
    > span {
      transition: color 0.3s ease;
      &:hover {
        color: color(primary-1000);
      }
    }
  }
  .rmdp-day-picker {
    padding: 16px $gap-xs;
    > div {
      width: 100%;
    }
    @include media-breakpoint-up(md) {
      padding-left: $gap-md;
      padding-right: $gap-md;
    }
  }
  .rmdp-week-day {
    @extend .text-caption;
    width: 36px;
    height: 24px;
    padding: 0 0 8px;
    color: color(secondary-800);
    font-weight: 400;
  }
  .rmdp-day {
    width: 36px;
    height: 36px;
    color: color(secondary-1000);
    > span {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 6px;
      transition: background-color 0.3s ease, color 0.3s ease;
    }
  }
  .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: color(secondary-100);
    color: color(secondary-1000);
  }
  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: color(primary-1000);
    color: white;
    box-shadow: none;
    &:hover {
      background-color: color(primary-1000);
      color: white;
    }
  }
  .rmdp-day.rmdp-today span {
    background-color: transparent;
    border: 1px solid color(primary-1000);
    color: color(secondary-1000);
  }
  .rmdp-day.rmdp-deactive,
  .rmdp-day.rmdp-disabled {
    color: color(secondary-300);
  }
  .rmdp-range {
    background-color: color(primary-1000) !important;
    color: white !important;
    box-shadow: none;
    transition: background-color 0.3s ease;
    &.start {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &.end {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    &.rmdp-today span {
      background-color: color(primary-1000);
      color: white;
    }
    span:hover {
      background-color: color(primary-1000) !important;
      color: white !important;
    }
  }
  .rmdp-ym .rmdp-day span {
    border-radius: 6px;
  }
}
