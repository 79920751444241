/* stylelint-disable selector-descendant-combinator-no-non-space */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid color(secondary-300);
  border-radius: $border-radius-sm;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: color(primary-1000);
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: color(primary-1000);
  box-shadow: none;
}
.ant-select {
  width: 100%;
  color: color(secondary-1000);
}
.ant-select-lg {
  @extend .text-body;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  padding: 0 12px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px;
  padding: 0 12px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 38px;
}
/* prettier-ignore */
.ant-select-single.ant-select-lg:not(.ant-select-customize-input, .ant-select-customize-input)
.ant-select-selection-search-input {
  height: 46px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  @extend .text-body;
  color: color(secondary-800);
  line-height: 38px !important;
}
/* prettier-ignore */
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
.ant-select-selector
.ant-select-selection-placeholder {
  line-height: 46px !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 38px !important;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 46px !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 32px;
}
.ant-select-arrow {
  @include flex-center;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  right: 16px;
  .anticon {
    display: none;
  }
  &::after {
    content: '\e90a';
    font-family: 'icomoon';
    font-size: 14px;
    line-height: 1;
    color: color(secondary-800);
    transition: transform 0.4s ease;
  }
  .ant-select-open &::after {
    transform: rotateX(180deg);
  }
}
.ant-select-dropdown {
  padding: 8px 0;
  border-radius: $border-radius-sm;
  box-shadow: $shadow3;
  .rc-virtual-list-scrollbar {
    right: 6px !important;
    width: 4px !important;
  }
  .rc-virtual-list-scrollbar-thumb {
    background-color: color(secondary-200) !important;
  }
}
.ant-select-item {
  @extend .text-body;
  padding: 10px 16px;
  color: color(secondary-1000);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: color(primary-100);
  color: color(secondary-1000);
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  position: relative;
  font-weight: 400;
  background-color: color(secondary-50);
  color: color(secondary-1000);
  &::after {
    content: '\e909';
    font-family: 'icomoon';
    display: block;
    position: absolute;
    top: 50%;
    right: 18px;
    margin-top: -7px;
    color: color(primary-1000);
    font-size: 14px;
    line-height: 1;
  }
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: color(secondary-800);
}
.ant-select-item-option-state {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-content {
  font-size: 12px;
}
.ant-select-multiple.ant-select-lg {
  .ant-select-selector {
    min-height: 48px;
  }
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 24px;
  line-height: 24px;
}
.ant-select-multiple {
  .ant-select-selection-item {
    align-items: center;
    margin: 4px 8px 4px 0;
    padding: 0 8px;
    background-color: color(primary-100);
    border: none;
    border-radius: 6px;
    color: color(primary-1000);
    .ant-select-selection-item-remove {
      @include flex-center;
      width: 16px;
      height: 16px;
      &::after {
        content: '\e90c';
        font-family: 'icomoon';
        font-size: 10px;
        line-height: 1;
      }
      .anticon {
        display: none;
      }
    }
  }
  .ant-select-selection-item-remove {
    color: color(primary-1000);
    font-weight: 400;
    &:hover {
      color: color(primary-1000);
    }
  }
}
.ant-select-status-error.ant-select:not(.ant-select-disabled, .ant-select-customize-input) .ant-select-selector {
  border-color: color(red-1000) !important;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled, .ant-select-customize-input).ant-select-open
  .ant-select-selector,
.ant-select-status-error.ant-select:not(.ant-select-disabled, .ant-select-customize-input).ant-select-focused
  .ant-select-selector {
  box-shadow: none;
}
.ant-select-meridiem {
  .ant-select-selector {
    padding: 0 !important;
  }
  .ant-select-selection-item {
    text-align: center;
  }
}
.ant-select-meridiem-dropdown {
  padding-top: 0;
  padding-bottom: 0;
  .ant-select-item-option {
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    &::after {
      display: none !important;
    }
  }
}
.ant-select-item-group {
  background-color: color(secondary-50);
  color: color(secondary-900);
}
.ant-select-show-search {
  &.ant-select--with-search-icon {
    .ant-select-selector {
      padding-left: 36px;
      &::before {
        content: '\e911';
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        color: color(secondary-800);
        font-family: 'icomoon';
        font-size: 20px;
        line-height: 1;
      }
    }
    .ant-select-selection-search {
      left: 36px;
    }
  }
  &.ant-select--with-state-icon {
    .ant-select-selector {
      padding-left: 36px;
      &::before {
        content: '\e931';
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        color: color(secondary-800);
        font-family: 'icomoon';
        font-size: 20px;
        line-height: 1;
      }
    }
    .ant-select-selection-search {
      left: 36px;
    }
  }
}
