.custom-stepper {
  justify-content: flex-start;
  align-items: center;
  .ant-steps-item-finish,
  .ant-steps-item-wait {
    .ant-steps-item-content {
      display: none;
    }
  }
  .ant-steps-item {
    flex: none !important;
    height: 24px;
    padding-left: 0 !important;
    &:not(last-child) {
      .ant-steps-item-tail {
        order: 3;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        display: block;
        width: 6px;
        padding: 0;
        margin-right: 10px;
        &::after {
          background-color: color(secondary-100) !important;
        }
        @include media-breakpoint-up(sm) {
          width: 32px;
        }
      }
    }
  }
  .ant-steps-item-container {
    display: flex;
    align-items: center;
  }
  .ant-steps-item-title {
    padding: 0;
    margin-left: 8px;
    margin-right: 18px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: color(primary-1000);
    letter-spacing: -0.2px;
    &::after {
      display: none !important;
    }
  }
  .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    line-height: 1;
    border-radius: $border-radius-md;
    border: none !important;
    background-color: color(primary-100) !important;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: color(green-200) !important;
    border-radius: 50%;
  }
  .ant-steps-icon {
    color: color(secondary-800) !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
  .ant-steps-item-active .ant-steps-icon {
    color: color(primary-1000) !important;
  }
  .ant-steps-item-finish .ant-steps-icon {
    color: color(green-accent) !important;
  }
  .ant-steps-item-tail {
    display: none;
  }
}
