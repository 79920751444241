.container {
  width: 100%;
  max-width: 1128px;
  margin-right: auto;
  margin-left: auto;
  padding-right: $gap-xs;
  padding-left: $gap-xs;
  transition: max-width 0.3s ease;
  @include media-breakpoint-up(md) {
    padding-right: $gap-md;
    padding-left: $gap-md;
  }
}
.container-xs {
  @extend .container;
  max-width: 594px;
}
.container-sm {
  @extend .container;
  max-width: 864px;
}
.container-lg {
  @extend .container;
  max-width: 1248px;
}
.container-xl {
  @extend .container;
  max-width: 1272px;
}
.container-fluid {
  @extend .container;
  max-width: 100%;
}
.main-wrap {
  width: 100%;
  min-height: 100vh;
}
.main-content {
  flex: 1;
  transition: margin-left 0.3s ease;
  @include media-breakpoint-up(xl) {
    margin-left: $main-sidebar-width;
  }
}
.main-content--sidebar-closed {
  & > .container {
    max-width: 1272px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: $closed-sidebar-width;
  }
}
.main-card {
  padding: $gap-xs;
  background-color: white;
  border-radius: $border-radius-sm;
  word-wrap: break-word;
  box-shadow: $shadow3;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    padding: $gap-md;
  }
}
.main-dash-list {
  margin: 0;
  padding: 0;
  list-style: inside '- ';
}
