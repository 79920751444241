.ant-radio-wrapper {
  @extend .text-body;
  margin-right: 0;
  color: color(secondary-1000);
  &:hover,
  &:focus {
    .ant-radio-inner {
      border-color: color(primary-1000);
    }
  }
}
.ant-radio-checked::after {
  display: none;
}
.ant-radio-inner {
  width: 18px;
  height: 18px;
  border-color: color(primary-200);
  border-width: 2px;
  &::after {
    background-color: color(primary-1000);
  }
  .ant-radio-checked & {
    border-color: color(primary-1000);
  }
  .ant-radio-input:focus + & {
    border-color: color(primary-1000);
    box-shadow: none;
  }
  .ant-radio-disabled & {
    background-color: white;
    border-color: color(secondary-300) !important;
    &::after {
      background-color: color(secondary-300);
    }
  }
}
span.ant-radio + * {
  padding-right: 0;
  padding-left: 12px;
}
.ant-radio-disabled + span {
  color: color(secondary-300);
}
