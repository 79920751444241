.main-header {
  @include flex-center-y;
  position: sticky;
  top: 0;
  z-index: 99;
  height: $main-header-height;
  padding: 0 $gap-xs;
  background-color: white;
  box-shadow: inset 0 -1px 0 color(secondary-100);
  @include media-breakpoint-up(md) {
    padding-right: $gap-md;
    padding-left: $gap-md;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 62px;
  }
}
.ant-btn.main-header__dropdown {
  @include flex-center-y;
  width: auto;
  height: auto;
  padding: 0;
  border: none;
  color: color(secondary-800);
  .icon {
    transition: transform 0.3s ease;
  }
  &.ant-dropdown-open {
    .icon {
      transform: rotateX(180deg);
    }
  }
  @include media-breakpoint-down(md) {
    margin-left: 24px;
  }
}
.main-header__dropdown-overlay.ant-dropdown {
  top: 68px !important;
  right: 12px !important;
  left: auto !important;
  @include media-breakpoint-up(md) {
    right: 4px !important;
  }
  @include media-breakpoint-up(xl) {
    right: 16px !important;
  }
  .ant-dropdown-menu {
    width: 198px;
    @include media-breakpoint-up(md) {
      width: 284px;
    }
  }
  .ant-dropdown-menu-item:not(.ant-dropdown-menu-item--no-hover) {
    @include media-breakpoint-down(md) {
      padding: 0 8px;
    }
  }
}
.header-empty {
  @include flex-center-y;
  position: sticky;
  top: 0;
  z-index: 1;
  height: 64px;
  padding: 0 $gap-xs;
  background-color: white;
  box-shadow: $shadow2;
  @include media-breakpoint-up(md) {
    padding-right: 38px;
    padding-left: 38px;
  }
}
.header-static {
  @include flex-center-y;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 64px;
  background-color: white;
  box-shadow: inset 0 -1px 0 color(secondary-100);
}
