.main-footer {
  @include flex-center-y;
  height: 100px;
  margin-top: auto;
  background-color: white;
  box-shadow: inset 0 1px 0 color(secondary-100);
  @include media-breakpoint-up(md) {
    height: 50px;
  }
  @include media-breakpoint-up(xl) {
    height: auto;
  }
}
.main-footer__social-link {
  @include flex-center;
  width: 36px;
  height: 36px;
  border: 1px solid color(secondary-300);
  border-radius: 50%;
  .icon {
    color: color(secondary-800);
    transition: color 0.3s ease;
  }
  & + & {
    margin-left: 8px;
  }
  &:hover .icon {
    color: color(secondary-900);
  }
}
