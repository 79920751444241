.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: $main-sidebar-width;
  height: 100%;
  background-color: color(primary-1000);
  transition: width 0.3s ease;
  @include media-breakpoint-down(xl) {
    display: none;
  }
}
.main-sidebar--closed {
  width: $closed-sidebar-width;
  .main-sidebar__link {
    padding: 0 12px;
  }
  .main-sidebar__link-text {
    display: none;
  }
}
.main-sidebar__head {
  @include flex-center-y;
  height: $main-header-height;
  padding: 0 24px;
  box-shadow: inset 0 -1px 0 color(white-50);
  transition: padding 0.3s ease;
  .main-sidebar--closed & {
    padding: 0 20px;
  }
}
.main-sidebar__toggle {
  position: absolute;
  top: 72px;
  right: -11px;
  width: 22px;
  min-width: unset;
  height: 22px;
  background: color(accent-300);
  border: none;
  color: white;
  .icon {
    font-size: 14px;
  }
  &:hover,
  &:focus {
    background: color(accent-300);
    border: none;
    color: white;
  }
}
.main-sidebar__link {
  @include flex-center-y;
  position: relative;
  height: 48px;
  padding: 0 8px;
  background-color: transparent;
  color: color(white-50);
  border-radius: $border-radius-lg;
  transition: color 0.3s ease, background-color 0.3s ease, padding 0.3s ease;
  &:hover,
  &:focus {
    background-color: color(menu-bg);
    color: color(white-50);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 4px;
    height: 28px;
    background-color: color(accent-300);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .icon {
    font-size: 24px;
  }
}
.main-sidebar__link--active {
  background-color: color(menu-bg);
  .icon {
    color: color(accent-300);
  }
  .main-sidebar__link-text {
    color: white;
  }
  &::after {
    opacity: 1;
  }
}
.main-sidebar__link-text {
  margin-left: 12px;
  font-weight: 600;
}
