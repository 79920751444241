.onboarding__work-wrapper {
  .ant-checkbox-wrapper {
    width: 130px;
  }
}
.onboarding__work-grid {
  display: grid;
  grid-template-columns: 1fr 20px 1fr 32px;
  align-items: baseline;
  & + & {
    margin-top: 20px;
  }
  .ant-form-item-explain-error {
    width: 68px;
    white-space: nowrap;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: 160px 20px 160px 32px;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: 120px 20px 120px 32px;
  }
}
.onboarding__work-input {
  display: grid;
  grid-template-columns: 1fr 46px;
  grid-gap: 4px;
  width: 120px;
  .ant-input {
    padding-right: 4px;
    padding-left: 4px;
  }
}
.onboarding__field--cancelation-scenario {
  width: 55px;
  .ant-form-item-explain-error {
    white-space: nowrap;
  }
}

.onboarding__signature {
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  width: 100%;
  height: 280px;
  &:hover {
    .onboarding__signature-actions {
      background-color: rgba(0, 1, 40, 0.6);
    }
    .onboarding__signature-action {
      opacity: 1;
    }
  }
}
.onboarding__signature-actions {
  background-color: rgba(0, 1, 40, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.3s ease;
}
.onboarding__signature-action {
  color: white;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
