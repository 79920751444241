.in-white {
  color: white !important;
}
.in-darkest {
  color: color(black-text) !important;
}
.in-dark {
  color: color(secondary-1000) !important;
}
.in-black {
  color: color(secondary-900) !important;
}
.in-secondary {
  color: color(secondary-800) !important;
}
.in-secondary-300 {
  color: color(secondary-300) !important;
}
.in-disabled {
  color: color(secondary-100) !important;
}
.in-primary {
  color: color(primary-1000) !important;
}
.in-primary-800 {
  color: color(primary-800) !important;
}
.in-red {
  color: color(red-1000) !important;
}
.in-light-red {
  color: color(red-800) !important;
}
.in-green {
  color: color(green-accent) !important;
}
.in-yellow {
  color: color(yellow-accent) !important;
}
.in-accent-300 {
  color: color(accent-300) !important;
}
.bg-white {
  background-color: white !important;
}
.bg-secondary {
  background-color: color(secondary-50) !important;
}
.bg-secondary-100 {
  background-color: color(secondary-100) !important;
}
.error-bg {
  background-color: color(bg-color) !important;
}
