$margins: (
  0: 0,
  3: 3px,
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  24: 24px,
  32: 32px,
  36: 36px,
  40: 40px,
  48: 48px,
  56: 56px,
  60: 60px,
  64: 64px,
  84: 84px,
  120: 120px,
  130: 130px,
  160: 160px,
  auto: auto,
);
$paddings: (
  0: 0,
  2: 2px,
  4: 4px,
  8: 8px,
  12: 12px,
  16: 16px,
  24: 24px,
  32: 32px,
  36: 36px,
  40: 40px,
  44: 44px,
  48: 48px,
  60: 60px,
  64: 64px,
  80: 80px,
  96: 96px,
  100: 100px,
  128: 128px,
);
$font-sizes: (
  8: 8px,
  10: 10px,
  12: 12px,
  14: 14px,
  16: 16px,
  18: 18px,
  24: 24px,
  36: 36px,
  48: 48px,
);
$utilities: (
  'max-width': (
    property: max-width,
    class: max-w,
    responsive: true,
    values: (
      100: 100%,
      340: 340px,
      364: 364px,
      480: 480px,
      504: 504px,
      522: 522px,
      576: 576px,
      588: 588px,
      596: 596px,
    ),
  ),
  'min-width': (
    property: min-width,
    class: min-w,
    responsive: true,
    values: (
      24: 24px,
      72: 72px,
      96: 96px,
      112: 112px,
      120: 120px,
      136: 136px,
      140: 140px,
      160: 160px,
      180: 180px,
      200: 200px,
      252: 252px,
    ),
  ),
  'margin-top': (
    property: margin-top,
    class: mt,
    responsive: true,
    values: $margins,
  ),
  'margin-right': (
    property: margin-right,
    class: mr,
    responsive: true,
    values: $margins,
  ),
  'margin-bottom': (
    property: margin-bottom,
    class: mb,
    responsive: true,
    values: $margins,
  ),
  'margin-left': (
    property: margin-left,
    class: ml,
    responsive: true,
    values: $margins,
  ),
  'padding': (
    property: padding,
    class: p,
    responsive: true,
    values: $paddings,
  ),
  'padding-x': (
    property: padding-right padding-left,
    class: px,
    responsive: true,
    values: $paddings,
  ),
  'padding-y': (
    property: padding-top padding-bottom,
    class: py,
    responsive: true,
    values: $paddings,
  ),
  'padding-top': (
    property: padding-top,
    class: pt,
    responsive: true,
    values: $paddings,
  ),
  'padding-right': (
    property: padding-right,
    class: pr,
    responsive: true,
    values: $paddings,
  ),
  'padding-bottom': (
    property: padding-bottom,
    class: pb,
    responsive: true,
    values: $paddings,
  ),
  'padding-left': (
    property: padding-left,
    class: pl,
    responsive: true,
    values: $paddings,
  ),
  'font-size': (
    property: font-size,
    class: fs,
    responsive: true,
    values: $font-sizes,
  ),
  'font-weight': (
    property: font-weight,
    class: fw,
    values: (
      400: 400,
      600: 600,
    ),
  ),
  'cursor': (
    property: cursor,
    class: cursor,
    values: auto pointer grab,
  ),
  'color': null,
);

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}
