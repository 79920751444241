/* prettier-ignore */
@font-face {
  font-family: 'icomoon';
  src:
    url('/fonts/icomoon/icomoon.woff') format('woff'),
    url('/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('/fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
.icon {
  font-family: 'icomoon' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: never;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-alert::before {
  content: '\e900';
}
.icon-analytics::before {
  content: '\e941';
}
.icon-arrow-down::before {
  content: '\e93e';
}
.icon-arrow-left::before {
  content: '\e926';
}
.icon-arrow-right::before {
  content: '\e90f';
}
.icon-arrow-up::before {
  content: '\e93f';
}
.icon-attachment::before {
  content: '\e92c';
}
.icon-back::before {
  content: '\e91a';
}
.icon-bell::before {
  content: '\e944';
}
.icon-calendar::before {
  content: '\e901';
}
.icon-calendar-brake::before {
  content: '\e940';
}
.icon-camera::before {
  content: '\e932';
}
.icon-camera-off::before {
  content: '\e933';
}
.icon-cancel::before {
  content: '\e945';
}
.icon-chat-o::before {
  content: '\e934';
}
.icon-checked::before {
  content: '\e909';
}
.icon-chevron-double-left::before {
  content: '\e902';
}
.icon-chevron-double-right::before {
  content: '\e903';
}
.icon-chevron-down::before {
  content: '\e90a';
}
.icon-chevron-left::before {
  content: '\e904';
}
.icon-chevron-right::before {
  content: '\e905';
}
.icon-chevron-up::before {
  content: '\e90b';
}
.icon-clock::before {
  content: '\e91b';
}
.icon-clock1::before {
  content: '\e946';
}
.icon-cross::before {
  content: '\e90c';
}
.icon-doctor::before {
  content: '\e918';
}
.icon-download::before {
  content: '\e930';
}
.icon-edit::before {
  content: '\e923';
}
.icon-eye::before {
  content: '\e906';
}
.icon-eye-crossed::before {
  content: '\e907';
}
.icon-facebook::before {
  content: '\e91d';
}
.icon-file::before {
  content: '\e935';
}
.icon-globe::before {
  content: '\e92e';
}
.icon-info::before {
  content: '\e908';
}
.icon-info-o::before {
  content: '\e936';
}
.icon-instagram::before {
  content: '\e91e';
}
.icon-list::before {
  content: '\e925';
}
.icon-loader::before {
  content: '\e92d';
}
.icon-location::before {
  content: '\e931';
}
.icon-log-out::before {
  content: '\e90e';
}
.icon-mark-read::before {
  content: '\e943';
}
.icon-menu::before {
  content: '\e90d';
}
.icon-message::before {
  content: '\e947';
}
.icon-mic-off::before {
  content: '\e937';
}
.icon-minus::before {
  content: '\e912';
}
.icon-notification::before {
  content: '\e914';
}
.icon-options-horizontal::before {
  content: '\e92a';
}
.icon-options-vertical::before {
  content: '\e92b';
}
.icon-order::before {
  content: '\e919';
}
.icon-payment::before {
  content: '\e915';
}
.icon-pdf::before {
  content: '\e91f';
}
.icon-phone::before {
  content: '\e92f';
}
.icon-phone-on::before {
  content: '\e942';
}
.icon-plus::before {
  content: '\e913';
}
.icon-repeat::before {
  content: '\e927';
}
.icon-resize::before {
  content: '\e910';
}
.icon-screen-normal::before {
  content: '\e938';
}
.icon-screen-share::before {
  content: '\e939';
}
.icon-screen-split::before {
  content: '\e93a';
}
.icon-screen-swap::before {
  content: '\e93b';
}
.icon-search::before {
  content: '\e911';
}
.icon-settings::before {
  content: '\e916';
}
.icon-settings-o::before {
  content: '\e93c';
}
.icon-support::before {
  content: '\e921';
}
.icon-thumbs::before {
  content: '\e920';
}
.icon-transfer::before {
  content: '\e928';
}
.icon-trash::before {
  content: '\e924';
}
.icon-twitter::before {
  content: '\e93d';
}
.icon-update::before {
  content: '\e929';
}
.icon-upload::before {
  content: '\e91c';
}
.icon-user::before {
  content: '\e917';
}
.icon-video::before {
  content: '\e922';
}
.icon-success::before {
  color: green;
  content: '\e909';
}
.icon-warning::before {
  color: orange;
  content: '\e908';
}
.icon-error::before {
  color: red;
  content: '\e90c';
}
