.ant-tabs {
  @extend .text-body;
  color: color(secondary-1000);
}
.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 16px;
  &::before {
    display: none;
  }
}
.ant-tabs-tab {
  padding: 0 16px 20px;
  color: color(secondary-800);
  font-weight: 600;
  &:hover {
    color: color(black-text);
  }
  & + & {
    margin: 0;
  }
  &.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: color(black-text);
  }
  &.ant-tabs-tab-disabled {
    color: color(secondary-300);
  }
}
.ant-tabs-tab-btn:hover,
.ant-tabs-tab-btn:focus {
  color: color(secondary-1000);
}
.ant-tabs-ink-bar {
  background: color(primary-800);
}
