.react-tel-input {
  @extend .text-body;
  font-family: inherit;
  &:hover .form-control,
  &:focus-within .form-control {
    border-color: color(primary-1000);
  }
  .form-control {
    width: 100%;
    height: 48px;
    border: 1px solid color(secondary-300);
    border-radius: $border-radius-sm;
    letter-spacing: normal;
    transition: border-color 0.3s ease;
    .ant-form-item-has-error & {
      border-color: color(red-1000);
    }
  }
  .flag-dropdown {
    background: none !important;
    border: none;
  }
  .selected-flag {
    border-radius: $border-radius-sm 0 0 $border-radius-sm;
    background: none !important;
  }
  .country-list {
    margin-top: 4px;
    margin-bottom: 4px;
    box-shadow: $shadow3;
    .country {
      padding-top: 10px;
      padding-bottom: 10px;
      &:hover,
      &.highlight {
        background-color: color(primary-100);
      }
    }
  }
}
