.ant-notification {
  margin-right: 12px;
  @include media-breakpoint-up(md) {
    margin-right: 16px;
  }
}
.ant-notification-notice {
  width: 100vw;
  max-width: calc(100vw - 24px);
  margin-bottom: 16px;
  padding: 14px 12px;
  border-radius: 6px;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    width: auto;
    max-width: calc(100vw - 32px);
  }
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 32px;
  margin-bottom: 0;
  padding-right: 36px;
}
.ant-notification-notice-icon {
  @include flex-center;
  width: 20px;
  height: 20px;
  margin-left: 0;
  .icon {
    font-size: 18px;
  }
}
.ant-notification-notice-close {
  top: 14px;
  right: 12px;
}
.ant-notification-notice-close-x {
  @include flex-center;
  width: 20px;
  height: 20px;
  .icon {
    font-size: 12px;
  }
}
.ant-notification-notice-success {
  background-color: color(green-200);
  .ant-notification-notice-message {
    @extend .text-body;
    color: color(green-accent);
    font-weight: 600;
  }
  .ant-notification-notice-icon,
  .ant-notification-notice-close-x {
    .icon {
      color: color(green-accent);
    }
  }
}
.ant-notification-notice-info {
  background-color: color(primary-100);
  .ant-notification-notice-message {
    @extend .text-body;
    color: color(primary-1000);
    font-weight: 600;
  }
  .ant-notification-notice-icon,
  .ant-notification-notice-close-x {
    .icon {
      color: color(primary-1000);
    }
  }
}
.ant-notification-notice-warning {
  background-color: color(yellow-200);
  .ant-notification-notice-message {
    @extend .text-body;
    color: color(yellow-accent);
    font-weight: 600;
  }
  .ant-notification-notice-icon,
  .ant-notification-notice-close-x {
    .icon {
      color: color(yellow-accent);
    }
  }
}
.ant-notification-notice-error {
  background-color: color(red-200);
  .ant-notification-notice-message {
    @extend .text-body;
    color: color(red-1000);
    font-weight: 600;
  }
  .ant-notification-notice-icon,
  .ant-notification-notice-close-x {
    .icon {
      color: color(red-1000);
    }
  }
}
