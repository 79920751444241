.static-menu__item {
  @include flex-center-y;
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  color: color(secondary-900) !important;
  font-weight: 600;
}
.static-menu__item--active::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: color(primary-1000);
}
.static-nav__item {
  position: relative;
  padding: 0 10px;
  &:not(:last-child) {
    margin-right: 40px;
  }
  a {
    @include flex-center-y;
    height: $main-header-height;
    color: color(secondary-900) !important;
    font-weight: 600;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  &:hover,
  &.static-nav__item--active {
    &::before {
      background-color: color(primary-1000);
    }
  }
}
.static__banner {
  padding: 48px 0;
  background-color: color(primary-100);
  @include media-breakpoint-up(md) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.static__banner-big {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 560px;
  color: color(white-100);
  background-position: center;
  background-size: cover;
  text-align: center;

  &.static__banner-big--contact-us {
    background-image: url('/images/static/contact_us.webp');
  }

  &.static__banner-big--about-us {
    background-image: url('/images/static/about_us.webp');

    .description {
      max-width: 588px;
    }
  }
}
.static__banner-big .title {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 96px;
}
.static__banner-big .description {
  font-family: $main-font-family;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}
.static__service {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  gap: 24px;
  background: #ffffff;
}
.static__service-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px;
  width: 384px;
  font-family: $main-font-family;
  box-shadow: $shadow3;
  border-radius: 2px;
  text-align: center;
}
.static__service .logo {
  height: 100px;
  width: 100px;
  background-color: #c0e5e4;
  border-radius: 50px;
  align-self: center;
}
.static__service .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}
.static__service .description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: color(secondary-1000);
}
.static__service--about-us {
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
  .static__service-box {
    width: 30%;
    box-shadow: none;
  }
}
.static__float-nav {
  position: sticky;
  top: 104px;
  z-index: 1;
  padding: 12px;
  border-radius: $border-radius-sm;
  box-shadow: inset 0 0 0 1px color(secondary-100);
}
.static__ordered-list {
  @extend .text-subheader;
  margin: 16px 0;
  counter-reset: custom-counter;
  list-style: none;
}
.static__ordered-list li {
  display: flex;
  counter-increment: custom-counter;
  & + li {
    margin-top: 24px;
  }
}
.static__ordered-list li::before {
  content: counter(custom-counter);
  @include flex-center;
  flex: none;
  width: 40px;
  height: 40px;
  margin-right: 16px;
  background-color: color(primary-100);
  border-radius: 50%;
  color: color(primary-1000);
  @extend .text-body;
  font-weight: 600;
}
.work-with-us__banner {
  padding: 80px 0;
  background-image: url('/images/static/work_with_us.webp');
  background-position: center;
  background-size: cover;
  @include media-breakpoint-up(md) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 192px;
    padding-bottom: 192px;
  }
}
.work-with-us__img img {
  display: block;
  width: 100%;
  height: auto;
}
.work-with-us__title {
  position: relative;
  padding-bottom: 6px;
  @extend .text-display;
  @extend .text-xl-jumbo;
  font-weight: 400;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100px;
    height: 2px;
    background-color: color(primary-800);
    border-radius: $border-radius-sm;
  }
}

.section__title {
  position: relative;
  padding-bottom: 6px;
  @extend .text-display;
  @extend .text-xl-jumbo;
  font-weight: 400;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100px;
    height: 2px;
    background-color: color(primary-800);
    border-radius: $border-radius-sm;
  }
}
.section__img {
  display: block;
  width: 100%;
  height: auto;
}

.faq-tabs {
  .ant-tabs-nav-list {
    justify-content: space-between;
    width: 100%;
    margin: 0 -16px;
  }
  .ant-tabs-tab {
    display: block;
    width: 100%;
    .faq-tabs__tab {
      border: 1px solid color(secondary-100);
      border-radius: 2px;
      transition: border 0.5s;
      .faq-tabs__text {
        font-size: 20px;
      }
    }
    &.ant-tabs-tab-active {
      .faq-tabs__tab {
        border: 1px solid color(primary-1000);
        .faq-tabs__text {
          color: color(primary-1000);
        }
      }
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}

.faq_collapse {
  background-color: #ffffff;
  .faq_collapse__item {
    border-bottom: 1px solid color(secondary-100) !important;
    .ant-collapse-header {
      padding: 24px 0 !important;
      .ant-collapse-header-text {
        color: color(primary-1000);
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 24px;
      }
    }
    .ant-collapse-content {
      padding: 0 0 24px !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
      ul,
      ol {
        padding-left: 15px;
      }
    }
  }

  .ant-collapse-item {
    position: relative;
    .ant-collapse-header {
      &:before,
      &:after {
        content: '';
        background-color: color(primary-1000);
        position: absolute;
        top: 50%;
        right: 15px;
        height: 2px;
        width: 14px;
        transition: opacity 0.25s;
      }
      &:after {
        opacity: 1;
        transform: rotate(90deg);
      }
    }
    &.ant-collapse-item-active {
      .ant-collapse-header {
        &:after {
          opacity: 0;
        }
      }
    }
  }
}

.list-disc {
  list-style-type: disc;
  padding: 20px;
}
