.main-link {
  color: color(primary-1000);
  text-decoration: none;
  &:hover,
  &:focus {
    color: color(primary-hover);
    outline: 0;
    text-decoration: underline;
  }
}
