.payment-card {
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $shadow3;
  .ant-alert {
    border-radius: 0;
  }
}
.payment-card__head {
  @include flex-center-y;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid color(secondary-100);
}
.payment-card__item {
  padding: 24px;
  border-bottom: 1px solid color(secondary-100);
}
