.appointment-card__avatar {
  margin-bottom: 8px;
  padding: $gap-xs;
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $shadow3;
  text-align: center;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: column;
    flex: none;
    width: 209px;
    margin-right: 8px;
    margin-bottom: 0;
    padding: 16px $gap-md;
  }
  .ant-avatar-string {
    font-size: 36px;
    font-weight: 600;
  }
}
.appointment-reschedule__modal {
  .appointment-reschedule__not-your-state {
    color: color(primary-800);
    font-size: 12px;
    font-weight: bold;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    position: relative;
    z-index: 1;
    padding-top: 24px;
    box-shadow: $shadow5;
  }
  @include media-breakpoint-up(md) {
    max-width: 100%;
  }
}
.appointment-reschedule__info {
  @include flex-center-x;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid color(secondary-100);
}
.appointment-reschedule__toolbar {
  @include flex-center-y;
  padding: 16px $gap-xs;
  background-color: color(secondary-50);
  @include media-breakpoint-up(md) {
    padding-right: $gap-md;
    padding-left: $gap-md;
  }
}
.appointment-reschedule__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  [data-simplebar] {
    width: 100%;
    max-height: calc(100vh - 321px);
    @include media-breakpoint-up(md) {
      max-height: calc(100vh - 477px);
    }
  }
}
.appointment-reschedule__popover,
.appointment-list__calendar-popover {
  @include flex-center-y;
  cursor: pointer;
  .icon {
    color: color(secondary-800);
    transition: color 0.3s ease;
  }
}
.appointment-reschedule__popover-overlay {
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: $border-radius-lg;
    box-shadow: $shadow3;
  }
  .ant-popover-inner-content {
    width: 276px;
    padding: 0;
  }
  .multi-date-calendar {
    border-radius: $border-radius-lg;
    .rmdp-header,
    .rmdp-day-picker {
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}
.appointment-reschedule__weekday {
  display: flex;
  align-items: flex-start;
  padding: 16px $gap-xs;
  &:not(:last-child) {
    border-bottom: 1px solid color(secondary-100);
  }
  @include media-breakpoint-up(md) {
    padding-right: $gap-md;
    padding-left: $gap-md;
  }
}
.appointment-reschedule__weekday-title {
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 32px;
  background-color: color(primary-100);
  border-radius: $border-radius-lg;
  color: color(primary-1000);
}
.appointment-reschedule__weekday-title--unavailable {
  background-color: color(secondary-50);
  color: color(secondary-800);
}
.appointment-reschedule__weekday--unavailable {
  background-color: color(secondary-50);
  color: color(secondary-800);
  width: 100% !important;
}
.appointment-reschedule__hours-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  .ant-btn {
    @extend .text-body;
    font-weight: 400;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(5, 1fr);
    // grid-template-columns: repeat(5, minmax(0,1fr));
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}
.appointment-list {
  flex: 1;
  min-height: 0;
}
.appointment-list__wrap {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 180px);
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $shadow2;
  overflow: hidden;
}
.appointment-list__controls {
  padding: 16px 12px;
  @include media-breakpoint-up(md) {
    padding: 24px 24px 16px;
  }
}
.appointment-list__view {
  display: flex;
  .appointment-list__view-btn:first-child {
    border-top-left-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
  }
  .appointment-list__view-btn:last-child {
    border-top-right-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
  }
}
.appointment-list__view-btn {
  background-color: color(secondary-50);
  border-color: color(secondary-50);
  color: color(secondary-800);
  border-radius: 0;
  &:hover,
  &:focus {
    background-color: color(secondary-100);
    border-color: color(secondary-100);
    color: color(secondary-800);
  }
  &:active {
    background-color: color(secondary-200);
    border-color: color(secondary-200);
    color: color(secondary-800);
  }
}
.appointment-list__view-btn--active {
  &,
  &:hover,
  &:focus,
  &:active {
    color: color(primary-1000);
  }
}
.appointment-list__search {
  width: 100%;
  @include media-breakpoint-up(md) {
    flex: 1;
    width: auto;
  }
}
.appointment-list__timezone {
  width: calc(100% - 92px);
  .ant-select {
    .ant-select-selector {
      padding-left: 36px;
      &::before {
        content: '\e92e';
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        color: color(secondary-800);
        font-family: 'icomoon';
        font-size: 20px;
        line-height: 1;
      }
    }
    .ant-select-selection-search {
      left: 36px;
    }
  }

  @include media-breakpoint-up(md) {
    width: 240px;
  }
  @include media-breakpoint-up(xl) {
    width: 320px;
  }
}
.appointment-list__skeleton {
  height: 100%;
}
.appointment-height-100 {
  height: 100%;
}
.appointment-list__toolbar {
  position: relative;
  z-index: 1;
  @include flex-center-y;
  justify-content: space-between;
  height: 36px;
  flex: none;
  background-color: color(secondary-50);
  border-top: 1px solid color(secondary-100);
  border-bottom: 1px solid color(secondary-100);
  box-shadow: $shadow2;
}
.appointment-list__up-btn {
  @include flex-center;
  width: 77px;
  height: 100%;
  border-right: 1px solid color(secondary-100);
  @include media-breakpoint-up(md) {
    width: 84px;
  }
}
.appointment-list__up-btn--active {
  background-color: color(primary-100);
  .icon-scroll-active {
    color: color(primary-800) !important;
  }
}

.appointment-list__filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.appointment-list__filter-popover {
  padding-top: 4px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: $border-radius-sm;
    box-shadow: $shadow3;
    overflow: hidden;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}
.appointment-list__filter-footer {
  display: flex;
  justify-content: flex-end;
  padding: 14px 16px;
  box-shadow: $shadow5;
}
.appointment-list__calendar-popover-overlay {
  padding-top: 5px;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    border-radius: $border-radius-lg;
    box-shadow: $shadow4;
  }
  .ant-popover-inner-content {
    width: 280px;
    padding: 0;
  }
  .multi-date-calendar {
    border-radius: $border-radius-lg;
    .rmdp-header,
    .rmdp-day-picker {
      padding-right: 12px;
      padding-left: 12px;
    }
    .only.rmdp-month-picker {
      width: auto;
    }
  }
}
.appointment__list-view__item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid color(secondary-100);
  &:last-child {
    padding-bottom: 24px;
    border-bottom: none;
  }
}
.appointment__list-view__item-date {
  display: flex;
  flex-direction: column;
  min-width: 118px;
  padding: 6px 0 6px 4px;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    min-width: 168px;
    padding-left: 16px;
  }
}
.appointment__list-view__item-day {
  @include flex-center;
  @extend .text-subheader;
  width: 32px;
  height: 32px;
}
.appointment__list-view__item-day--today {
  border-radius: 50%;
  background-color: color(primary-1000);
  color: white;
}
.appointment__list-view__item-info {
  @include flex-center-y;
  padding: 4px 8px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover,
  &.ant-popover-open {
    background-color: color(primary-100);
  }
  @include media-breakpoint-up(md) {
    padding-right: 16px;
  }
}
.appointment__list-view__item-disc {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.appointment__list-view__item-disc--cancelled {
  background-color: #b1bac9;
}
.appointment__list-view__item-disc--past {
  background-color: #b2bec3;
}
.appointment__list-view__item-disc--lightgreen {
  background-color: #7eec9a;
}
.appointment__list-view__item-disc--orchid {
  background-color: #b97ef8;
}
.appointment__list-view__item-disc--khaki {
  background-color: #fdcb6e;
}
.appointment__list-view__item-disc--cornflowerblue {
  background-color: #5396ff;
}
.appointment__list-view__item-disc--greenyellow {
  background-color: #cdf051;
}
.appointment__list-view__item-disc--cadetblue {
  background-color: #00b894;
}
.appointment__list-view__item-disc--violet {
  background-color: #f885fe;
}
.appointment__list-view__item-disc--skyblue {
  background-color: #81ecec;
}
.appointment__popover {
  padding-bottom: 14px;
  .ant-popover-inner {
    border-radius: $border-radius-sm;
    box-shadow: $shadow3;
  }
  .ant-popover-inner-content {
    width: calc(100vw - 24px);
    padding: 12px 12px 8px;
    @include media-breakpoint-up(md) {
      width: 440px;
      padding: 24px 24px 16px;
    }
  }
  .price {
    text-wrap: nowrap;
  }
  .user-title {
    overflow-wrap: anywhere;
  }
}
.appointment__calendar {
  .rbc-time-view {
    border: none;
  }
  .rbc-time-header {
    display: none;
  }
  .rbc-time-content {
    border-top: none;
  }
  .rbc-time-gutter.rbc-time-column {
    min-width: 77px;
    background-color: color(secondary-50);
    border-right: 1px solid color(secondary-100);
    > .rbc-timeslot-group {
      display: flex;
      flex-direction: column;
      .rbc-time-slot {
        display: none;
        &:first-child {
          display: block;
          padding: 8px 0;
          color: color(secondary-900);
          text-align: center;
        }
        .rbc-label {
          padding: 0;
        }
      }
    }
    @include media-breakpoint-up(md) {
      min-width: 84px;
    }
  }
  .rbc-day-slot.rbc-time-column {
    .rbc-time-slot {
      border-top: none;
      &:not(:first-child) {
        box-shadow: inset 0 1px 0 color(secondary-100);
      }
    }
  }
  .rbc-timeslot-group {
    min-height: 112px;
    border-bottom: none;
  }
  .rbc-timeslot-group + .rbc-timeslot-group {
    min-height: 113px;
    border-top: 1px solid color(secondary-100);
  }
  .rbc-time-content > * + * > * {
    border-left: none;
  }
  .rbc-today {
    background-color: transparent;
  }
  .rbc-current-time-indicator {
    height: 2px;
    background-color: color(red-1000);
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-8px, -50%);
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: color(red-1000);
    }
  }
  .rbc-day-slot {
    .rbc-events-container {
      margin-right: 0;
    }
    .rbc-event {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      max-width: 495px;
      padding: 0 12px;
      border: 2px solid white;
      border-radius: $border-radius-sm;
      color: color(secondary-1000);
      transition: border-color 0.3s ease;
    }
    .rbc-event--past {
      background-color: #f0f2f3;
      border-left-color: #b2bec3;
      &:hover,
      &.ant-popover-open {
        border-color: #b2bec3;
      }
    }
    .rbc-event--cancelled {
      background: repeating-linear-gradient(115deg, #ecf1f8, #ecf1f8 12px, #f9fafc 12px, #f9fafc 24px);
      border-left-color: #b1bac9;
      &:hover,
      &.ant-popover-open {
        border-color: #b1bac9;
      }
    }
    .rbc-event--lightcyan {
      background-color: #ddfbf3;
      border-left-color: #7eec9a;
      &:hover,
      &.ant-popover-open {
        border-color: #7eec9a;
      }
    }
    .rbc-event--lightcyan-light {
      background-color: #e6fbfb;
      border-left-color: #81ecec;
      &:hover,
      &.ant-popover-open {
        border-color: #81ecec;
      }
    }
    .rbc-event--lavender {
      background-color: #ecebff;
      border-left-color: #b97ef8;
      &:hover,
      &.ant-popover-open {
        border-color: #b97ef8;
      }
    }
    .rbc-event--lavender-light {
      background-color: #ddeaff;
      border-left-color: #5396ff;
      &:hover,
      &.ant-popover-open {
        border-color: #5396ff;
      }
    }
    .rbc-event--oldlace {
      background-color: #fff5e2;
      border-left-color: #fdcb6e;
      &:hover,
      &.ant-popover-open {
        border-color: #fdcb6e;
      }
    }
    .rbc-event--oldlace-light {
      background-color: #f8fee5;
      border-left-color: #cdf051;
      &:hover,
      &.ant-popover-open {
        border-color: #cdf051;
      }
    }
    .rbc-event--paleturquoise {
      background-color: #ccf1ea;
      border-left-color: #00b894;
      &:hover,
      &.ant-popover-open {
        border-color: #00b894;
      }
    }
    .rbc-event--lavenderblush {
      background-color: #fee7ff;
      border-left-color: #f885fe;
      &:hover,
      &.ant-popover-open {
        border-color: #f885fe;
      }
    }
  }
}
