.ant-slider {
  margin: 0;
  &:hover {
    .ant-slider-rail {
      background-color: color(primary-200);
    }
    .ant-slider-track,
    .ant-slider-handle:not(.ant-tooltip-open) {
      background-color: color(primary-hover);
      border-color: white;
    }
  }
}
.ant-slider-rail {
  background-color: color(primary-100);
}
.ant-slider-track {
  background-color: color(primary-1000);
}
.ant-slider-handle {
  width: 20px;
  height: 20px;
  margin-top: -8px;
  background-color: color(primary-1000);
  border: 3px solid white;
  box-shadow: $shadow2;
  &:hover,
  &:focus {
    background-color: color(primary-hover);
    border-color: white;
    box-shadow: $shadow2;
  }
  &.ant-tooltip-open {
    border-color: white;
  }
}
.ant-slider-tooltip {
  padding-bottom: 0;
  .ant-tooltip-arrow {
    display: none;
  }
  .ant-tooltip-inner {
    @extend .text-caption;
    min-height: 20px;
    padding: 2px 4px;
    background-color: color(secondary-1000);
    border-radius: 4px;
    color: color(secondary-200);
    box-shadow: 0 4px 4px rgb(0 0 0 / 0.25);
  }
}
