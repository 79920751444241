.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 8px 12px;
  border-radius: $border-radius-lg;
  color: color(secondary-1000);
  font-size: 1.4rem;
  line-height: (20 / 14);
  font-weight: 600;
  box-shadow: none;
  &::after {
    display: none;
  }
  &[disabled],
  &[disabled]:hover {
    background-color: color(secondary-50);
    border-color: color(secondary-50);
    color: color(secondary-300);
  }
  &.ant-dropdown-trigger {
    .icon-chevron-down {
      transition: transform 0.3s ease;
    }
    &.ant-dropdown-open .icon-chevron-down {
      transform: rotateX(180deg);
    }
  }
}
.ant-btn-circle {
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 32px;
}
.ant-btn-icon-only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  padding: 0;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: $border-radius-lg;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 32px;
  height: 32px;
  border-radius: $border-radius-lg;
}
.ant-btn.ant-btn-sm {
  @extend .text-caption;
  height: 32px;
  padding: 8px;
}
.ant-btn.ant-btn-lg {
  height: 48px;
  padding: 12px;
}
.ant-btn-primary {
  text-shadow: none;
  background-color: color(primary-1000);
  border-color: color(primary-1000);
  color: white;
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(primary-hover);
    border-color: color(primary-hover);
    color: white;
  }
  &:not([disabled]):active {
    background-color: color(primary-pressed);
    border-color: color(primary-pressed);
    color: white;
  }
}
.ant-btn-secondary {
  background-color: color(primary-100);
  border-color: color(primary-100);
  color: color(primary-1000);
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(primary-200);
    border-color: color(primary-200);
    color: color(primary-1000);
  }
  &:not([disabled]):active {
    background-color: color(primary-300);
    border-color: color(primary-300);
    color: color(primary-1000);
  }
}
.ant-btn-tertiary {
  background-color: transparent;
  border-color: color(primary-1000);
  color: color(primary-1000);
  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    background-color: white;
    border-color: color(primary-1000);
    color: color(primary-1000);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: transparent;
    border-color: color(secondary-300);
    color: color(secondary-300);
  }
}
.ant-btn-warning {
  background-color: transparent;
  border-color: color(yellow-accent);
  color: color(yellow-accent);
  &:not([disabled]):hover,
  &:not([disabled]):focus,
  &:not([disabled]):active {
    background-color: white;
    border-color: color(yellow-accent);
    color: color(yellow-accent);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: transparent;
    border-color: color(secondary-300);
    color: color(secondary-300);
  }
}
.ant-btn-dangerous {
  background-color: color(red-1000);
  border-color: color(red-1000);
  color: white;
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(red-800);
    border-color: color(red-800);
    color: white;
  }
  &:not([disabled]):active {
    background-color: color(red-pressed);
    border-color: color(red-pressed);
    color: white;
  }
}
.ant-btn-control {
  width: auto;
  min-width: 24px;
  height: auto;
  min-height: 24px;
  border: none;
  color: color(secondary-800);
  background-color: transparent;
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: transparent;
    color: color(secondary-1000);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: transparent;
    color: color(secondary-300);
  }
}
.ant-btn-control--count {
  @extend .text-small;
  @include flex-center;
  position: absolute;
  top: -6px;
  left: 50%;
  min-width: 18px;
  padding: 1px 4px;
  background-color: color(accent-800);
  border-radius: $border-radius-md;
  color: white;
  font-weight: 600;
  pointer-events: none;
}
.ant-btn-text {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  color: color(secondary-800);
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: transparent;
    color: color(secondary-1000);
  }
  &:not([disabled]):active {
    background-color: transparent;
    color: color(secondary-1000);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: transparent;
    color: color(secondary-300);
  }
}
.ant-btn-link {
  width: auto;
  height: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  color: color(primary-1000);
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: transparent;
    color: color(primary-hover);
  }
  &:not([disabled]):active {
    background-color: transparent;
    color: color(primary-pressed);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: transparent;
    color: color(secondary-300);
  }
}
.ant-btn-timer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: color(secondary-50);
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(secondary-50);
    color: color(secondary-1000);
  }
  &[disabled],
  &[disabled]:hover {
    background-color: color(secondary-50);
    color: color(secondary-1000);
  }
}
.ant-btn-delete {
  background-color: color(red-100);
  border-color: color(red-100);
  color: color(red-1000);
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(red-hover);
    border-color: color(red-hover);
    color: color(red-1000);
  }
  &:not([disabled]):active {
    background-color: color(red-pressed);
    border-color: color(red-pressed);
    color: color(red-1000);
  }
}
.ant-btn-filter {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  background-color: color(secondary-100);
  border-color: color(secondary-100);
  color: color(secondary-800);
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: color(secondary-200);
    border-color: color(secondary-200);
    color: color(secondary-800);
  }
  &:not([disabled]):active {
    background-color: color(secondary-300);
    border-color: color(secondary-300);
    color: color(secondary-800);
  }
  &.ant-popover-open {
    color: color(primary-1000) !important;
  }
  .ant-tag {
    min-width: 20px;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 6px;
  }
}
.ant-btn-filter--active {
  color: color(primary-1000) !important;
}
.btn-scroll-top {
  min-width: 48px;
  min-height: 48px;
}
