.ant-table {
  @extend .text-body;
  color: color(secondary-1000);
  border-radius: 0;
}
.ant-table-thead > tr > th {
  padding: 8px 16px;
  background: color(secondary-100);
  border-bottom: none;
  color: color(secondary-900);
  font-weight: 400;
  .ant-btn {
    min-width: unset;
    min-height: unset;
  }
}
.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-thead
  > tr
  > th:not(:last-child, .ant-table-selection-column, .ant-table-row-expand-icon-cell, [colspan])::before {
  display: none;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid color(secondary-100);
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: color(secondary-50);
}
