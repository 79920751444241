.yarl__container {
  background-color: $overlay-color;
}
.yarl__slide {
  padding: 64px 12px 32px !important;
  @include media-breakpoint-up(md) {
    padding: 64px 24px 32px !important;
  }
}
.yarl__toolbar {
  gap: 24px;
  padding: 24px 24px 0 0;
}
.yarl__toolbar_btn {
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  color: color(white-80);
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.8));
  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;
    color: white;
  }
}
.yarl__slide_attachment {
  max-width: 100%;
  padding: 36px 24px;
  background-color: white;
  border-radius: $border-radius-sm;
  text-align: center;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    min-width: 344px;
    padding-top: 36px;
    padding-bottom: 56px;
  }
}
.yarl__slide_captions_container {
  padding: 24px 120px 0 24px;
  background: none;
}
.yarl__slide_title {
  @extend .text-subheader;
  max-width: 100%;
}
