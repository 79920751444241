.simplebar-track.simplebar-vertical {
  width: 16px;
}
.simplebar-track.simplebar-horizontal {
  height: 16px;
  .simplebar-scrollbar {
    top: 6px;
    height: 4px;
  }
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}
.simplebar-scrollbar::before {
  left: 6px;
  right: 6px;
  background: color(secondary-200);
  border-radius: 4px;
}
