.ant-dropdown {
  color: color(secondary-1000);
}
.ant-dropdown-menu {
  padding: 8px 0;
  border-radius: $border-radius-sm;
  box-shadow: $shadow3;
}
.ant-dropdown-menu-item {
  @include flex-center-y;
  @extend .text-body;
  min-height: 48px;
  padding: 0 16px;
  color: color(secondary-900);
  font-weight: 600;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: color(secondary-50);
    color: color(secondary-1000);
    &.ant-dropdown-menu-item--no-hover {
      cursor: default;
      background-color: transparent;
    }
  }
  .ant-dropdown-menu-title-content {
    width: 100%;
  }
}
.ant-dropdown-menu-item--log-out {
  color: color(secondary-300);
}
.ant-dropdown-menu-item-divider {
  margin: 8px 16px;
  background-color: color(secondary-100);
}
