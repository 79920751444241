.doctors__filters {
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $shadow2;
  padding: 24px 24px 16px;
}
.doctors-list__search-btn {
  width: 100%;
  text-align: center;
  border-radius: 2px;
  @include media-breakpoint-up(md) {
    border-radius: 0;
  }
}
.doctor-list__filters {
  display: flex;
  gap: 8px;
  overflow-x: auto;
}
.doctor-list__filter-popover {
  .ant-popover-inner-content {
    width: 100%;
    padding: 0;
  }
}
.doctors__pricerange {
  padding: 48px 16px 24px;
}
.doctors-list__search,
.doctors-list__state {
  @include media-breakpoint-up(md) {
    .ant-select-selector {
      border-radius: 0 !important;
    }
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 4px;
  }
}
.doctors-list__top {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.doctors-list__count {
  font-weight: 600;
  @extend .text-subheader;
  letter-spacing: -0.2px;
  color: color(secondary-1000);
}
.doctors-list__sort {
  display: flex;
  align-items: center;
}
.doctors-list__sort-label {
  white-space: nowrap;
  color: color(secondary-900);
}
.doctors-list__sort .ant-form-item {
  width: 170px;
  margin-bottom: 0;
}
.doctors-item {
  background-color: white;
  border-radius: $border-radius-sm;
  box-shadow: $shadow2;
  padding: 24px 24px 16px;
  margin-bottom: 16px;
}
.doctors-item__top {
  border-bottom: 1px solid color(secondary-100);
}
.doctors-item__top-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.doctors-item__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 8px;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 0;
  }
}
.doctors-item__photo {
  margin-bottom: 16px;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    margin-right: 24px;
  }
  img {
    display: block;
  }
}
.doctors-item__name {
  font-weight: 600;
  @extend .text-subheader-big;
  color: color(primary-1000);
}
.doctors-item__position {
  font-weight: 600;
  @extend .text-body;
  letter-spacing: -0.2px;
  color: color(secondary-1000);
}
.doctors-item__gender {
  @extend .text-body;
  color: color(secondary-900);
}
.doctors-item__booking-info {
  display: flex;
  align-items: center;
}
.doctors-item__price {
  display: flex;
  align-items: flex-end;
  height: 36px;
  margin-bottom: 8px;
  color: color(secondary-1000);
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    margin-right: 24px;
  }
}
.doctors-item__price-value {
  @extend .text-headline;
}
.doctors-item__price-label {
  @extend .text-body;
  padding-bottom: 5px;
}
.doctors-item__view-all {
  font-weight: 400 !important;
}
.doctors-item__next {
  @extend .text-caption;
  background-color: color(primary-100);
  border-radius: $border-radius-lg;
  padding: 8px;
  font-weight: 600;
  color: color(primary-1000);
  text-align: center;
}
.doctors-list__week {
  display: flex;
  flex-wrap: nowrap;
  margin-left: -5px;
  margin-right: -5px;
  overflow-x: auto;
}
.doctors-list__day {
  flex: 1;
  min-width: 110px;
  margin: 0 5px;
  background-color: color(primary-100);
  color: color(primary-1000);
  text-align: center;
  padding: 8px 12px;
  border-radius: 8px;
  &.disabled {
    background-color: color(secondary-50);
    color: color(secondary-800);
    .doctors-list__day-top {
      border-color: color(secondary-800);
    }
  }
}
.doctors-list__day-top {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid color(primary-1000);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.doctors-list__day-item {
  font-size: 10px;
  line-height: 16px;
  white-space: nowrap;
  margin-bottom: 2px;
  cursor: pointer;
}
.doctors-profile__tabs {
  margin-top: -40px;
  .ant-tabs-nav {
    padding-left: 12px;
    padding-right: 12px;
    @include media-breakpoint-up(md) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}
.doctors-profile__schedule-wrap [data-simplebar] {
  max-height: calc(100vh - 459px);
  @include media-breakpoint-up(md) {
    max-height: calc(100vh - 479px);
  }
}
.doctors-profile__schedule-head {
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  min-width: 672px;
  padding-bottom: 8px;
  background-color: white;
  .appointment-reschedule__weekday-title {
    flex-direction: column;
    width: 100%;
    height: 48px;
  }
}
.doctors-profile__schedule-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  min-width: 672px;
  .appointment-reschedule__weekday-title {
    width: 100%;
  }
  .ant-btn {
    @extend .text-body;
    font-weight: 400;
  }
}
.doctors-profile__schedule-grid__unavailable {
  @extend .appointment-reschedule__weekday-title;
  @extend .appointment-reschedule__weekday-title--unavailable;
  position: sticky;
  top: 56px;
  height: calc(100vh - 515px);
  @include media-breakpoint-up(md) {
    height: calc(100vh - 535px);
  }
}
