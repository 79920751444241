.angle-down {
  border-width: 6px 5px 0 5px;
  border-color: currentColor transparent transparent transparent;
  border-style: solid;
  cursor: pointer;
}

.angle-up {
  border-width: 0px 5px 6px 5px;
  border-color: transparent transparent currentColor transparent;
  border-style: solid;
  cursor: pointer;
}
