.ant-modal-wrap {
  display: flex;
  flex-direction: column;
}
.ant-modal-mask {
  background-color: $overlay-color;
}
.ant-modal {
  @extend .text-body;
  color: color(secondary-1000);
  top: auto;
  max-width: 100%;
  margin: auto 0 0;
  padding: 0;
  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
  @include media-breakpoint-up(md) {
    top: 100px;
    max-width: 528px;
    margin: 0 auto;
  }
}
.ant-modal--body-p-0 .ant-modal-body {
  padding: 0;
}
.ant-modal--footer-p-0 .ant-modal-footer {
  padding: 0;
}
.ant-modal-close {
  color: color(secondary-800);
  &:hover,
  &:focus {
    color: color(secondary-1000);
  }
}
.ant-modal-close-x {
  .icon {
    font-size: 14px;
  }
}
.ant-modal-content {
  border-radius: $border-radius-sm $border-radius-sm 0 0;
  box-shadow: none;
  @include media-breakpoint-up(md) {
    border-radius: $border-radius-sm;
  }
}
.ant-modal-header {
  padding: 16px 56px 16px $gap-xs;
  border-bottom: none;
  border-radius: $border-radius-sm $border-radius-sm 0 0;
  color: color(secondary-1000);
  box-shadow: inset 0 -1px 0 color(secondary-100);
  @include media-breakpoint-up(md) {
    padding-left: $gap-md;
  }
}
.ant-modal-title {
  @extend .text-subheader;
  color: color(secondary-1000);
  font-weight: 600;
}
.ant-modal-body {
  @extend .text-body;
  padding: $gap-md $gap-xs;
  @include media-breakpoint-up(md) {
    padding-right: $gap-md;
    padding-left: $gap-md;
  }
}
.ant-modal-footer {
  @include flex-center-y;
  justify-content: flex-end;
  padding: 0 $gap-xs $gap-md;
  border-top: none;
  border-radius: 0;
  & .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: 0;
  }
  @include media-breakpoint-up(md) {
    padding-right: $gap-md;
    padding-left: $gap-md;
    border-radius: 0 0 $border-radius-sm $border-radius-sm;
  }
}
