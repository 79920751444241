.quest-block {
  margin-bottom: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: white;
}
.quest-block__container {
  padding-right: 24px;
  padding-left: 24px;
}
.quest-step-num {
  @include flex-center;
  width: 20px;
  height: 20px;
  margin-right: 16px;
  background-color: color(primary-100);
  border-radius: 6px;
  color: color(primary-hover);
  font-weight: 600;
  font-size: 12px;
  line-height: (16 / 12);
}
.quest-step-num--inactive {
  background-color: color(secondary-100);
  color: color(secondary-800);
}
.quest-step-num--success {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: color(green-200);
  color: color(green-accent);
  .icon {
    font-size: 16px;
  }
}
.quest-divider {
  height: 2px;
  margin-bottom: 16px;
  background-color: color(secondary-100);
}
.quest-divider--thin {
  height: 1px;
}
.quest-payment {
  display: flex;
  margin-bottom: 16px;
  padding: 16px;
  border: 1px solid color(secondary-100);
  border-radius: 2px;
}
.quest-payment-total {
  @include flex-center-y;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 8px 24px;
  background-color: color(secondary-50);
  font-weight: 600;
}
