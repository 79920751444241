.text-small {
  font-size: 1rem !important;
  line-height: (16 / 10) !important;
}
.text-caption {
  font-size: 1.2rem !important;
  line-height: (16 / 12) !important;
}
.text-body {
  font-size: 1.4rem !important;
  line-height: (20 / 14) !important;
}
.text-xl-body {
  @include media-breakpoint-up(xl) {
    font-size: 1.4rem !important;
    line-height: (20 / 14) !important;
  }
}
.text-subheader {
  font-size: 1.6rem !important;
  line-height: (24 / 16) !important;
}
.text-subheader-big {
  font-size: 1.8rem !important;
  line-height: (24 / 18) !important;
}
.text-title {
  font-size: 2rem !important;
  line-height: (32 / 20) !important;
}
.text-headline {
  font-size: 2.4rem !important;
  line-height: (36 / 24) !important;
}
.text-display {
  font-size: 3.6rem !important;
  line-height: (48 / 36) !important;
}
.text-md-display {
  @include media-breakpoint-up(md) {
    font-size: 3.6rem !important;
    line-height: (48 / 36) !important;
  }
}
.text-jumbo {
  font-size: 4.8rem !important;
  line-height: (64 / 48) !important;
}
.text-md-jumbo {
  @include media-breakpoint-up(md) {
    font-size: 4.8rem !important;
    line-height: (64 / 48) !important;
  }
}
.text-xl-jumbo {
  @include media-breakpoint-up(xl) {
    font-size: 4.8rem !important;
    line-height: (64 / 48) !important;
  }
}
.text-mega {
  font-size: 7.2rem !important;
  line-height: (96 / 72) !important;
}
.text-xl-mega {
  @include media-breakpoint-up(xl) {
    font-size: 7.2rem !important;
    line-height: (96 / 72) !important;
  }
}
.text-word-break {
  word-break: break-all !important;
}
