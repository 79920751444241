.ant-tooltip-inner {
  @extend .text-caption;
  padding: 16px;
  background-color: color(black-text);
  border-radius: 4px;
  box-shadow: none;
}
.ant-tooltip-arrow-content {
  box-shadow: none !important;
  &::before {
    background: color(black-text);
  }
}
