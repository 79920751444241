.vc-btn {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
  transition: background-color 0.3s;
  border-radius: 50%;
  color: color(secondary-900);
  background-color: white;
  border: 1px solid color(secondary-100);
  .vc-btn__icon {
    color: inherit;
  }
}
.vc-btn--danger {
  background-color: color(red-1000);
  color: white;
  border-color: color(red-1000);
  &:hover {
    background-color: color(red-800);
  }
}
.vc-btn--active {
  .vc-btn__icon--default {
    transform: rotateY(180deg);
  }
  .vc-btn__icon--active {
    transform: rotateY(0deg);
  }
}
.vc-btn__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  backface-visibility: hidden;
  transition: transform 0.3s;
}
.vc-btn__icon--active {
  transform: rotateY(180deg);
}
.vc-control-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  color: white;
  font-size: 20px;
  &:hover {
    background-color: rgba(white, 0.1);
  }
  .vc-control-btn__icon {
    font-size: inherit;
  }
}
.vc-control-btn--disabled {
  background-color: color(red-1000);
  color: white;
  &:hover {
    background-color: color(red-800);
  }
}

.vc-guest-name {
  text-align: center;
}
