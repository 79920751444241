.notifications__button {
  &:not(:hover):focus {
    color: #a3acbd !important;
    border-color: #a3acbd !important;
  }
}
.notifications-drawer {
  .ant-drawer-content-wrapper {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
  .ant-drawer-header {
    padding: 16px 24px;
  }
  .ant-drawer-body {
    overflow: hidden;
    height: calc(100vh - 60px);
  }
  .notifications__wrap {
    position: relative;
    height: 100%;
  }
  .notifications__header {
    position: absolute;
    top: 0;
    right: 18px;
    .notifications__read-all {
      background-color: white;
      display: inline-block;
      padding: 18px 10px 10px;
    }
  }
  .notifications__list {
    .infinite-scroll-component {
      padding: 18px 0 24px;
    }
  }
}
.notifications-section-title {
  padding: 0 24px;
}
.notification-item {
  padding: 8px 24px;
  &:hover {
    background-color: color(secondary-50);
  }
}
.notification-status {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  .icon {
    font-size: 10px;
    line-height: 10px;
  }
  &__schedule {
    background-color: color(yellow-200);
    color: color(yellow-1000);
  }
  &__cancel {
    background-color: color(red-100);
    color: color(red-1000);
  }
  &__notify {
    background-color: color(primary-100);
    color: color(primary-1000);
  }
  &__message {
    background-color: color(accent-50);
    color: color(accent-1000);
  }
  .is-read & {
    background-color: color(secondary-100) !important;
    color: color(secondary-300) !important;
  }
}
.notification-item-text,
.notification-item-timestamp {
  .is-read & {
    color: color(secondary-300) !important;
    a {
      color: color(secondary-300) !important;
    }
  }
}
.notification-mark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  &::after {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: '';
    background-color: color(red-1000);
  }
  &:hover {
    background-color: color(red-100);
  }
  .is-read & {
    &::after {
      background-color: color(secondary-100);
    }
    &:hover {
      background-color: color(secondary-300);
    }
  }
}
.notification-remove {
  .is-read & {
    opacity: 0;
    visibility: hidden;
  }
}
