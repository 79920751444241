/* stylelint-disable indentation */
/* stylelint-disable max-line-length */
.ant-picker {
  @extend .text-body;
  padding: 7px 12px;
  border: 1px solid color(secondary-300);
  border-radius: $border-radius;
  color: color(secondary-1000);
  &:hover,
  &.ant-picker-focused {
    border-color: color(primary-1000);
    box-shadow: none;
  }
}
.ant-picker-large {
  padding: 13px 12px;
  border: 1px solid color(secondary-300);
  border-radius: $border-radius;
  color: color(secondary-1000);
}
.ant-picker-focused .ant-picker-suffix {
  color: color(primary-1000);
}
.ant-picker-input > input {
  @extend .text-body;
  color: color(secondary-1000);
}
.ant-picker-large .ant-picker-input > input {
  @extend .text-body;
  &::placeholder {
    color: color(secondary-800);
  }
}
.ant-picker-dropdown {
  @extend .text-body;
  color: color(secondary-1000);
}
.ant-picker-panel-container {
  border-radius: 6px;
  box-shadow: $shadow4;
}
.ant-picker-panel {
  border: none;
  .ant-picker-footer {
    border-top: 1px solid color(secondary-100);
    border-bottom: none;
    line-height: 48px;
  }
}
.ant-picker-date-panel {
  width: 276px;
}
.ant-picker-header {
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid color(secondary-100);
  button {
    @extend .text-body;
    color: color(secondary-800);
  }
  > button {
    @include flex-center;
    width: 20px;
    min-width: unset;
    height: 20px;
    .icon {
      font-size: 14px;
    }
    &:hover {
      color: color(secondary-1000);
    }
  }
}
.ant-picker-header-view {
  font-weight: 400;
  line-height: 20px;
  button {
    color: color(secondary-1000);
    line-height: 20px;
    &:hover {
      color: color(primary-1000);
    }
  }
}
.ant-picker-date-panel .ant-picker-body {
  padding: 16px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-content th {
  @extend .text-caption;
  height: 24px;
  padding: 0 0 8px;
  color: color(secondary-800);
  font-weight: 400;
}
.ant-picker-content td {
  width: 36px;
  height: 36px;
}
.ant-picker-cell {
  padding: 0;
  color: color(secondary-300);
  &::before {
    height: 36px;
  }
  .ant-picker-cell-inner {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    line-height: 36px;
    &::before {
      border-radius: 6px;
    }
  }
}
.ant-picker-cell-today .ant-picker-cell-inner {
  color: color(primary-1000);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid color(primary-1000);
  border-radius: 6px;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected, .ant-picker-cell-range-start, .ant-picker-cell-range-end, .ant-picker-cell-range-hover-start, .ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: color(secondary-100);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: white;
  background: color(primary-1000);
}
.ant-picker-cell-in-view {
  color: color(secondary-1000);
}
.ant-picker-today-btn {
  color: color(primary-1000);
  &:hover {
    color: color(primary-hover);
  }
}
.ant-picker-clear {
  @include flex-center;
  width: 20px;
  height: 20px;
  color: color(secondary-800);
  .anticon {
    display: none;
  }
  &::after {
    content: '\e90c';
    font-family: 'icomoon';
    line-height: 1;
  }
  &:hover {
    color: color(secondary-1000);
  }
}
