.ant-anchor-wrapper {
  margin: 0;
  padding: 0;
}
.ant-anchor {
  @extend .text-body;
  padding: 0;
  color: color(secondary-1000);
}
.ant-anchor-ink {
  display: none;
}
.ant-anchor-link {
  padding: 10px 16px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  & + & {
    margin-top: 8px;
  }
}
.ant-anchor-link-active {
  background-color: color(primary-100);
  & > .ant-anchor-link-title {
    color: color(primary-1000);
  }
}
.ant-anchor-link-title {
  color: color(secondary-1000);
  white-space: normal;
  &:hover {
    color: color(primary-1000);
  }
}
