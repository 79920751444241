.auth__side {
  flex: none;
  display: flex;
  width: 460px;
  min-height: 100vh;
  padding: 32px;
  background-color: color(primary-1000);
  background-image: url('/images/auth/aside-bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  @include media-breakpoint-down(xl) {
    display: none;
  }
}
.auth__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 24px 0;
  background-color: white;
  @include media-breakpoint-up(md) {
    padding-top: 32px;
  }
}
.auth__socials-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: 0 12px;
  border: 1px solid white;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  opacity: 0.75;
  transition: opacity 0.3s ease;
  &:hover {
    color: white;
    opacity: 1;
  }
}
.auth__card {
  height: 100%;
  background-color: white;
  text-align: center;
  padding: 24px;
  border: 1px solid white;
  border-radius: $border-radius-sm;
  box-shadow: $shadow3;
  cursor: default;
  transition: border-color 0.3s ease;
  @include media-breakpoint-up(md) {
    padding: 32px;
  }
  &:hover {
    border-color: color(primary-1000);
  }
}
