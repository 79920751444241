$upload-bg: #f0f4f9;
$upload-btn-bg: #fafbfd;
$upload-btn-bg-hover: #ebeffb;
$upload-btn-border: #c8d2e2;
.ant-upload.profile__upload {
  height: 280px;
  background-color: $upload-bg;
  border: 1px dashed color(secondary-200);
  border-radius: $border-radius-sm;
  &.ant-upload-drag:not(.ant-upload-disabled):hover,
  &.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: color(primary-1000);
  }
  & .ant-upload {
    padding-top: 32px;
  }
  & .ant-upload-drag-container {
    vertical-align: top;
  }
}
.ant-upload.profile__attachment-upload {
  height: 80px;
  background-color: $upload-bg;
  border: 1px dashed color(secondary-200);
  border-radius: $border-radius-sm;
  .ant-upload-drag-container {
    color: color(secondary-800);
    transition: color 0.3s ease;
  }
  &.ant-upload-drag:not(.ant-upload-disabled):hover,
  &.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: color(primary-1000);
    .ant-upload-drag-container {
      color: color(primary-1000);
    }
  }
  & .ant-upload {
    padding: 0;
  }
}
.profile__upload-btn {
  @include flex-center;
  flex: none;
  width: 80px;
  height: 80px;
  background-color: $upload-btn-bg;
  background-origin: border-box;
  border: 1px dashed $upload-btn-border;
  border-radius: 50%;
  color: color(secondary-800);
  .icon {
    font-size: 24px;
  }
  &:hover,
  &:focus {
    background-color: $upload-btn-bg-hover;
    border-color: color(primary-1000);
    color: color(primary-1000);
  }
}
.profile__upload-crop {
  @include flex-center;
  background-color: $overlay-color;
  .ReactCrop__crop-selection {
    box-shadow: 0 0 0 9999em $overlay-color;
  }
}
.profile__contact-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px 12px;
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.profile__avatar-wrap {
  display: flex;
  position: relative;
  @include media-breakpoint-up(xl) {
    display: block;
    border-radius: 50%;
    &:hover .profile__avatar-control {
      opacity: 1;
    }
  }
}
.profile__avatar {
  width: 80px;
  height: 80px;
  // border: 2px solid white;
  border-radius: 50%;
  box-shadow: $shadow1;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.profile__avatar-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  margin-left: 8px;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 76px;
    height: 76px;
    margin-left: 0;
    background-color: $overlay-color;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}
.profile__avatar-btn {
  width: 28px;
  height: 28px;
  .icon {
    font-size: 14px;
  }
}
.profile__view {
  @include flex-center-y;
  gap: 2px;
  padding: 2px;
  background-color: color(secondary-50);
  border-radius: $border-radius-sm;
}
.profile__view-btn {
  flex: 1;
  height: 36px;
  background-color: transparent;
  border: none;
  border-radius: $border-radius-sm;
  color: color(secondary-800);
  font-weight: 400;
  &:not(.profile__view-btn--active):hover,
  &:not(.profile__view-btn--active):focus {
    background-color: transparent;
    color: color(primary-1000);
  }
}
.profile__view-btn--active {
  background-color: white;
  color: color(primary-1000);
  box-shadow: $shadow1;
  &:hover,
  &:focus {
    background-color: white;
    color: color(primary-1000);
  }
}
.profile__work-title {
  @include flex-center-y;
  @extend .text-body;
  height: 40px;
  font-weight: 600;
  @include media-breakpoint-up(md) {
    width: 102px;
  }
}
.profile__work-title--edit {
  @include media-breakpoint-up(md) {
    width: 131px;
  }
}
.profile__work-grid-wrapper {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.profile__work-grid {
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  align-items: center;
  & + & {
    margin-top: 16px;
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: 160px 20px 160px;
  }
  @include media-breakpoint-up(xl) {
    grid-template-columns: 120px 20px 120px;
  }
}
.profile__working-brake {
  padding: $gap-xs;
  border-right: 1px solid color(secondary-100);
  border-left: 1px solid color(secondary-100);
  @include media-breakpoint-up(md) {
    padding: $gap-md;
  }
}
.profile__working-brake-control {
  @include flex-center-y;
  width: 100%;
  height: 48px;
  padding: 0 12px;
  border: 1px solid color(secondary-200);
  border-radius: $border-radius-sm;
  font-weight: 400;
  &:hover,
  &:focus {
    color: color(secondary-1000);
  }
}
.profile__working-brake-panel {
  .ant-picker-panel-container {
    border-radius: $border-radius-lg;
    box-shadow: $shadow3;
  }
  .ant-picker-date-panel,
  .ant-picker-month-panel,
  .ant-picker-year-panel,
  .ant-picker-decade-panel {
    width: 100%;
  }
  .ant-picker-footer-extra {
    @include flex-center-y;
    justify-content: flex-end;
    padding: 24px;
  }
}
.profile__working-brake-panel__head {
  @include flex-center-y;
  padding: 14px 12px 12px;
  border-bottom: 1px solid color(secondary-100);
}
.profile__calendar-wrap {
  overflow-x: auto;
  border: 1px solid color(secondary-100);
  border-top: none;
}
.profile__calendar {
  min-width: 670px;
  .rbc-month-view {
    border: none;
  }
  .rbc-month-header {
    position: relative;
    z-index: 1;
    background-color: color(secondary-100);
    box-shadow: $shadow2;
  }
  .rbc-header {
    @extend .text-caption;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    font-weight: 400;
  }
  .rbc-month-row {
    height: 100px;
    flex: none;
    + .rbc-month-row {
      border-top: 1px solid color(secondary-100);
    }

    @include media-breakpoint-up(xl) {
      height: 136px;
    }
  }
  .rbc-row-content {
    display: none;
  }
}
.profile__calendar-toolbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 12px 12px;
  border: 1px solid color(secondary-100);
  border-bottom: none;
  @include media-breakpoint-up(md) {
    padding: 28px 24px 24px;
  }
}
.profile__calendar-day {
  display: flex;
  position: relative;
  flex: 1;
  transition: background-color 0.3s ease;
  & + & {
    border-left: 1px solid color(secondary-100);
  }
  .profile__calendar-tooltip {
    color: color(secondary-800);
  }
  &.ant-dropdown-trigger {
    cursor: pointer;
  }
  &.ant-dropdown-open {
    background-color: color(primary-100);
  }
}
.profile__calendar-day--off-range {
  color: color(secondary-800);
  .profile__calendar-content {
    color: color(secondary-800);
  }
}
.profile__calendar-day--unavailable {
  background-color: color(secondary-50);
  color: color(secondary-800);
  pointer-events: none;
  &.profile__calendar-day--custom {
    .profile__calendar-tooltip {
      color: color(secondary-800);
    }
    &::after {
      display: none;
    }
  }
  .profile__calendar-content {
    color: color(secondary-800);
  }
}
.profile__calendar-day--today .profile__calendar-date {
  background-color: color(primary-800);
  border-radius: 50%;
  color: white;
}
.profile__calendar-day--custom {
  .profile__calendar-tooltip {
    color: color(primary-800);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: color(primary-800);
  }
}
.profile__calendar-day--break {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: color(secondary-800);
  }
}
.profile__calendar-date {
  @extend .text-caption;
  @include flex-center;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 22px;
  height: 22px;
  font-weight: 600;
}
.profile__calendar-tooltip {
  position: absolute;
  top: 8px;
  right: 8px;
  transition: color 0.3s ease;
  &:hover {
    color: color(primary-800);
  }
}
.profile__calendar-content {
  @extend .text-small;
  position: absolute;
  top: 24px;
  right: 0;
  bottom: 0;
  left: 0;
  color: color(secondary-1000);
  text-align: center;
  @include media-breakpoint-up(xl) {
    top: 40px;
  }
}
.profile__calendar-dropdown {
  .ant-dropdown-menu {
    padding-top: 8px;
    padding-bottom: 8px;
    border: 1px solid color(secondary-100);
    box-shadow: $shadow4;
  }
  .ant-dropdown-menu-item {
    min-height: 40px;
    padding-right: 8px;
    padding-left: 8px;
    color: color(secondary-1000);
    font-weight: 400;
    .icon {
      color: color(secondary-800);
    }
    @include media-breakpoint-up(xl) {
      min-width: 200px;
    }
  }
  &.ant-dropdown-placement-bottomLeft .ant-dropdown-menu {
    margin-top: -24px;
    margin-left: 24px;
  }
  &.ant-dropdown-placement-bottomRight .ant-dropdown-menu {
    margin-top: -24px;
    margin-right: 24px;
  }
  &.ant-dropdown-placement-topLeft .ant-dropdown-menu {
    margin-bottom: -24px;
    margin-left: 24px;
  }
  &.ant-dropdown-placement-topRight .ant-dropdown-menu {
    margin-bottom: -24px;
    margin-right: 24px;
  }
}
.profile__calendar-message {
  @include flex-center-x;
  position: fixed;
  bottom: 36px;
  left: 0;
  z-index: 10;
  width: 100%;
  transition: margin-left 0.3s ease;
  @include media-breakpoint-up(md) {
    bottom: 48px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: calc($main-sidebar-width / 2);
    .main-content--sidebar-closed & {
      margin-left: calc($closed-sidebar-width / 2);
    }
  }
}
.profile__field--cancelation-scenario {
  width: 55px;
  .ant-form-item-explain-error {
    white-space: nowrap;
  }
}
