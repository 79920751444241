.flex-start {
  @include flex-start;
}
.flex-center {
  @include flex-center;
}
.flex-center-x {
  @include flex-center-x;
}
.flex-center-y {
  @include flex-center-y;
}
.absolute-center {
  @include absolute-center;
}
.flex-1 {
  flex: 1 !important;
}
.flex-none {
  flex: none !important;
}
.h-24 {
  height: 24px !important;
}
.h-40 {
  height: 40px !important;
}
.h-48 {
  height: 48px !important;
}
.h-280 {
  height: 280px;
}
.ls-02 {
  letter-spacing: -0.2px !important;
}
